// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-progress { margin: 24px 0px; }
.service-progress .service-title { display: flex; }
.service-progress .service-title .title { margin-bottom: 24px; }
.service-progress .service-title .icon { width: 20px; height: 20px; color: #000000; margin-left: 4px; margin-top: 8px; }

.MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }
.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }
/* .MuiStepConnector-alternativeLabel { border-top-width: 6px !important; } 
.MuiStepConnector-alternativeLabel.Mui-active { background: #A5D6A7 !important; } 
.MuiStepConnector-alternativeLabel.Mui-disabled {  } */`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/PhotographerServices/StepProgressBar/StepProgressBar.css"],"names":[],"mappings":"AAAA,oBAAoB,gBAAgB,EAAE;AACtC,mCAAmC,aAAa,EAAE;AAClD,0CAA0C,mBAAmB,EAAE;AAC/D,yCAAyC,WAAW,EAAE,YAAY,EAAE,cAAc,EAAE,gBAAgB,EAAE,eAAe,EAAE;;AAEvH,+BAA+B,yBAAyB,EAAE;AAC1D,kCAAkC,yBAAyB,EAAE;AAC7D;;sDAEsD","sourcesContent":[".service-progress { margin: 24px 0px; }\n.service-progress .service-title { display: flex; }\n.service-progress .service-title .title { margin-bottom: 24px; }\n.service-progress .service-title .icon { width: 20px; height: 20px; color: #000000; margin-left: 4px; margin-top: 8px; }\n\n.MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }\n.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }\n/* .MuiStepConnector-alternativeLabel { border-top-width: 6px !important; } \n.MuiStepConnector-alternativeLabel.Mui-active { background: #A5D6A7 !important; } \n.MuiStepConnector-alternativeLabel.Mui-disabled {  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
