// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.review-section h3 {
    margin: 0;
    padding: 0;
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.review-section .reviews-slider p {
    text-align: center;
    margin-top: 32px;
}

.slick-prev:before,
.slick-next:before {
  color: #00BCD4 !important;
}

@media screen and (max-width: 500px) {
    .review-section h3 {
        font-size: 32px !important;
        line-height: 36px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/ReviewSection/ReviewSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,SAAS;IACT,UAAU;IACV,+BAA+B;IAC/B,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;IACI;QACI,0BAA0B;QAC1B,4BAA4B;IAChC;AACJ","sourcesContent":[".review-section {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.review-section h3 {\n    margin: 0;\n    padding: 0;\n    font-family: 'Lora', sans-serif;\n    font-style: normal;\n    font-weight: 1;\n    font-size: 48px;\n    line-height: 52px;\n    text-align: center;\n    color: #000000;\n}\n\n.review-section .reviews-slider p {\n    text-align: center;\n    margin-top: 32px;\n}\n\n.slick-prev:before,\n.slick-next:before {\n  color: #00BCD4 !important;\n}\n\n@media screen and (max-width: 500px) {\n    .review-section h3 {\n        font-size: 32px !important;\n        line-height: 36px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
