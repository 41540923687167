// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.swiper {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.tab-slide {
  text-align: center;
  font-size: 16px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  
}

.tab-slide img {
  display: block;
  width: 50%;
  height: 50%;
  object-fit: cover !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/MyAdds/tabstyles.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;;AAEd;;AAEA;EACE,cAAc;EACd,UAAU;EACV,WAAW;EACX,4BAA4B;AAC9B","sourcesContent":["\n.swiper {\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n}\n\n.tab-slide {\n  text-align: center;\n  font-size: 16px;\n  background-color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 40px;\n  \n}\n\n.tab-slide img {\n  display: block;\n  width: 50%;\n  height: 50%;\n  object-fit: cover !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
