// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-file-box { margin-bottom: 16px; border: 1px dashed #000000; box-sizing: border-box; border-radius: 8px; }
.document-file-box .button { padding: 94px 56px 95px 56px; font-weight: 500; text-transform: none; }
.document-file-box .button .button-info .header-content { display: flex; margin-bottom: 16px; }
.document-file-box .button .button-info .header-content .icon { margin-right: 15px; }
.document-file-box .button .button-info .header-content .text { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.document-file-box .button .button-info .footer-content { display: flex; justify-content: center; padding: 11px 8px; }
.document-file-box .button .button-info .footer-content .add-icon { width: 16px; height: 16px; margin-right: 8px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); }
.document-file-box .button .button-info .footer-content .text { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }
`, "",{"version":3,"sources":["webpack://./src/pages/Lawyer/components/DocumentFileBox/DocumentFileBox.css"],"names":[],"mappings":"AAAA,qBAAqB,mBAAmB,EAAE,0BAA0B,EAAE,sBAAsB,EAAE,kBAAkB,EAAE;AAClH,6BAA6B,4BAA4B,EAAE,gBAAgB,EAAE,oBAAoB,EAAE;AACnG,0DAA0D,aAAa,EAAE,mBAAmB,EAAE;AAC9F,gEAAgE,kBAAkB,EAAE;AACpF,gEAAgE,iBAAiB,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC9J,0DAA0D,aAAa,EAAE,uBAAuB,EAAE,iBAAiB,EAAE;AACrH,oEAAoE,WAAW,EAAE,YAAY,EAAE,iBAAiB,EAAE,gGAAgG,EAAE;AACpN,gEAAgE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,cAAc,EAAE","sourcesContent":[".document-file-box { margin-bottom: 16px; border: 1px dashed #000000; box-sizing: border-box; border-radius: 8px; }\n.document-file-box .button { padding: 94px 56px 95px 56px; font-weight: 500; text-transform: none; }\n.document-file-box .button .button-info .header-content { display: flex; margin-bottom: 16px; }\n.document-file-box .button .button-info .header-content .icon { margin-right: 15px; }\n.document-file-box .button .button-info .header-content .text { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }\n.document-file-box .button .button-info .footer-content { display: flex; justify-content: center; padding: 11px 8px; }\n.document-file-box .button .button-info .footer-content .add-icon { width: 16px; height: 16px; margin-right: 8px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); }\n.document-file-box .button .button-info .footer-content .text { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
