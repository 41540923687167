// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.starting-service-details { display: flex; }
.starting-service-details .icon { margin-right: 18px; align-self: flex-start; }
.starting-service-details .current-details .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.starting-service-details .current-details .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.starting-service-details .current-details .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Lawyer/LawyerServices/StartingService/StartingService.css"],"names":[],"mappings":"AAAA,4BAA4B,aAAa,EAAE;AAC3C,kCAAkC,kBAAkB,EAAE,sBAAsB,EAAE;AAC9E,oDAAoD,kBAAkB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AACtK,mDAAmD,mBAAmB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AACnL,0DAA0D,mBAAmB,EAAE,8BAA8B,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,cAAc,EAAE","sourcesContent":[".starting-service-details { display: flex; }\n.starting-service-details .icon { margin-right: 18px; align-self: flex-start; }\n.starting-service-details .current-details .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }\n.starting-service-details .current-details .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }\n.starting-service-details .current-details .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
