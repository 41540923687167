// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details { display: flex; }
.details .current-details .information .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .current-details .information .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }
@media screen and (max-width:768px){
  .details {
     display: flex; 
     flex-direction: column;
     gap:10px
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/ServiceCompletion/ServiceCompletion.css"],"names":[],"mappings":"AAAA,WAAW,aAAa,EAAE;AAC1B,gDAAgD,kBAAkB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,gBAAgB,EAAE;AACpL,+CAA+C,mBAAmB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,gBAAgB,EAAE;AACjM;EACE;KACG,aAAa;KACb,sBAAsB;KACtB;EACH;AACF","sourcesContent":[".details { display: flex; }\n.details .current-details .information .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }\n.details .current-details .information .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }\n@media screen and (max-width:768px){\n  .details {\n     display: flex; \n     flex-direction: column;\n     gap:10px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
