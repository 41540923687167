// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-image-component {
  height: 30px;
  width: 35px;
  border: 1px solid rgba(0, 188, 212, 1);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/BookIcon/BookIcon.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".book-image-component {\n  height: 30px;\n  width: 35px;\n  border: 1px solid rgba(0, 188, 212, 1);\n  border-radius: 5px;\n  padding: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
