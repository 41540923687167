// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-seller-video { text-align: center; }
.dialog-seller-video-content { padding: 64px 120px; }
.dialog-seller-video-content .title-header { padding: 0; margin-bottom: 32px; }
.dialog-seller-video-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-seller-video-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-seller-video-content .content .video { background: #E8E8E8; border-radius: 8px; overflow: hidden; }
.dialog-seller-video-content .content .video .icon { margin: 120px 220px; opacity: 0.2; height: 180px; }`, "",{"version":3,"sources":["webpack://./src/pages/Seller/MyProfile/VirtualAssistant/Video/Video.css"],"names":[],"mappings":"AAAA,uBAAuB,kBAAkB,EAAE;AAC3C,+BAA+B,mBAAmB,EAAE;AACpD,6CAA6C,UAAU,EAAE,mBAAmB,EAAE;AAC9E,oDAAoD,qBAAqB,EAAE,eAAe,EAAE,iBAAiB,EAAE,iBAAiB,EAAE,gBAAgB,EAAE;AACpJ,yDAAyD,kBAAkB,EAAE,SAAS,EAAE,WAAW,EAAE,eAAe,EAAE;;AAEtH,+CAA+C,mBAAmB,EAAE,kBAAkB,EAAE,gBAAgB,EAAE;AAC1G,qDAAqD,mBAAmB,EAAE,YAAY,EAAE,aAAa,EAAE","sourcesContent":[".dialog-seller-video { text-align: center; }\n.dialog-seller-video-content { padding: 64px 120px; }\n.dialog-seller-video-content .title-header { padding: 0; margin-bottom: 32px; }\n.dialog-seller-video-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }\n.dialog-seller-video-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }\n\n.dialog-seller-video-content .content .video { background: #E8E8E8; border-radius: 8px; overflow: hidden; }\n.dialog-seller-video-content .content .video .icon { margin: 120px 220px; opacity: 0.2; height: 180px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
