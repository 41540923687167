// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lawyer-services {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 64px 0px;
}
.lawyer-services > h3 {
  margin: 0;
  padding: 0;
  font-family: "Lora", sans-serif;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
}
.lawyer-services-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 32px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Lawyer/MyProfile/components/LawyerServices/LawyerServices.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAAI;EACI,SAAA;EACA,UAAA;EACA,+BAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,qCAAA;EACA,gBAAA;EACA,WAAA;AAER","sourcesContent":[".lawyer-services {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    margin: 64px 0px;\n    > h3 {\n        margin: 0;\n        padding: 0;\n        font-family: 'Lora', sans-serif;\n        font-style: normal;\n        font-weight: 1;\n        font-size: 48px;\n        line-height: 52px;\n        text-align: center;\n        color: #000000;\n        margin-bottom: 16px;\n    }\n    &-items {\n        display: grid;\n        grid-template-columns: repeat(4, 1fr);\n        column-gap: 32px;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
