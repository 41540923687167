// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generalTerms { margin-bottom: 128px; font-size: 18px; line-height: 32px; }
.generalTerms-container { margin: 0 149px }
.generalTerms .title { font-size: 25px; font-family: Roboto; font-weight: 700; margin-bottom: 10px; }
.generalTerms .title-sub { margin-left: 20px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }
.generalTerms .title-sub-sub { margin-left: 30px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }
.generalTerms .ul { margin-left: 60px; list-style-type: none; }

.generalTerms .paragraph{
  text-align: left !important;
}

@media screen and (max-width:900px){
  .generalTerms-container {
     margin: 0 80px;
     text-indent: 0 !important;
     }
  
}

@media screen and (max-width:600px){
  .generalTerms-container { margin: 0 30px }
  .generalTerms .ul { margin-left: 0px;}

}


`, "",{"version":3,"sources":["webpack://./src/pages/SecondaryPages/GeneralTerms/GeneralTerms.css"],"names":[],"mappings":"AAAA,gBAAgB,oBAAoB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC1E,0BAA0B,gBAAgB;AAC1C,uBAAuB,eAAe,EAAE,mBAAmB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE;AACpG,2BAA2B,iBAAiB,EAAE,eAAe,EAAE,mBAAmB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE;AAC3H,+BAA+B,iBAAiB,EAAE,eAAe,EAAE,mBAAmB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE;AAC/H,oBAAoB,iBAAiB,EAAE,qBAAqB,EAAE;;AAE9D;EACE,2BAA2B;AAC7B;;AAEA;EACE;KACG,cAAc;KACd,yBAAyB;KACzB;;AAEL;;AAEA;EACE,0BAA0B,eAAe;EACzC,oBAAoB,gBAAgB,CAAC;;AAEvC","sourcesContent":[".generalTerms { margin-bottom: 128px; font-size: 18px; line-height: 32px; }\n.generalTerms-container { margin: 0 149px }\n.generalTerms .title { font-size: 25px; font-family: Roboto; font-weight: 700; margin-bottom: 10px; }\n.generalTerms .title-sub { margin-left: 20px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }\n.generalTerms .title-sub-sub { margin-left: 30px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }\n.generalTerms .ul { margin-left: 60px; list-style-type: none; }\n\n.generalTerms .paragraph{\n  text-align: left !important;\n}\n\n@media screen and (max-width:900px){\n  .generalTerms-container {\n     margin: 0 80px;\n     text-indent: 0 !important;\n     }\n  \n}\n\n@media screen and (max-width:600px){\n  .generalTerms-container { margin: 0 30px }\n  .generalTerms .ul { margin-left: 0px;}\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
