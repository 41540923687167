// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-selectAd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.dialog-selectAd-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.dialog-selectAd-title > button {
  height: 21px;
  width: 21px;
  margin: 21px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.dialog-selectAd-title > button > img {
  width: 14px;
  height: 14px;
}
.dialog-selectAd-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 64px;
  width: 100%;
  max-width: 578px;
}
.dialog-selectAd-content > h3 {
  font-family: "Lora", sans-serif;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
.dialog-selectAd-content > p {
  max-width: 650px;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
}
.dialog-selectAd-content > .actions {
  display: flex;
  flex-direction: column;
}
.dialog-selectAd-content > .actions > button {
  text-align: center;
  margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/PhotographerServices/SelectAdToAddUploads/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAAI;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;EACA,eAAA;AAEN;AADM;EACE,WAAA;EACA,YAAA;AAGR;AACE;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,oBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAAI;EACE,+BAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AAEN;AAAI;EACE,gBAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;AAEN;AACI;EACE,aAAA;EACA,sBAAA;AACN;AAAM;EACE,kBAAA;EACA,gBAAA;AAER","sourcesContent":[".dialog-selectAd {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n\n  &-title {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n    > button {\n      height: 21px;\n      width: 21px;\n      margin: 21px;\n      background-color: transparent;\n      border: none;\n      cursor: pointer;\n      > img {\n        width: 14px;\n        height: 14px;\n      }\n    }\n  }\n  &-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    padding-bottom: 64px;\n    width: 100%;\n    max-width: 578px;\n    > h3 {\n      font-family: 'Lora', sans-serif;\n      font-style: normal;\n      font-weight: 1;\n      font-size: 48px;\n      line-height: 52px;\n      text-align: center;\n      color: #000000;\n      margin: 0;\n      padding: 0;\n      margin-bottom: 8px;\n    }\n    > p {\n      max-width: 650px;\n      margin: 0;\n      padding: 0;\n      margin-bottom: 24px;\n    }\n\n    > .actions {\n      display: flex;\n      flex-direction: column;\n      > button {\n        text-align: center;\n        margin-top: 16px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
