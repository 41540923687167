// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-info-location-wrapper .location-item-mb {
    margin-bottom: 16px;
}

.seller-info-location-wrapper .location-title {
    font-family: Lora;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
}

.seller-info-location-wrapper .location-label {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #000;
    cursor: default;
}

.seller-info-location-wrapper .location-autocomplete {
    background: #ededed;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}

.seller-info-location-wrapper .location-autocomplete .notchedOutline {
    border: none;
}

.seller-info-location-wrapper .location-autocomplete .muiFocused>input {
    border: none;
}

.seller-info-location-wrapper .text {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 30px;
    margin-left: 5px;
}

.custom-popper {
    width: 825px !important;
}

.custom-popper-higher-width {
    width: 1145px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/MyAdds/components/LocationDetails/LocationDetails.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,4BAA4B;IAC5B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".seller-info-location-wrapper .location-item-mb {\n    margin-bottom: 16px;\n}\n\n.seller-info-location-wrapper .location-title {\n    font-family: Lora;\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 26px;\n}\n\n.seller-info-location-wrapper .location-label {\n    font-family: Source Sans Pro;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 12px;\n    color: #000;\n    cursor: default;\n}\n\n.seller-info-location-wrapper .location-autocomplete {\n    background: #ededed;\n    border-radius: 4px;\n    font-family: Source Sans Pro;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n    color: rgba(0, 0, 0, 0.6);\n}\n\n.seller-info-location-wrapper .location-autocomplete .notchedOutline {\n    border: none;\n}\n\n.seller-info-location-wrapper .location-autocomplete .muiFocused>input {\n    border: none;\n}\n\n.seller-info-location-wrapper .text {\n    font-size: 14px;\n    font-weight: 400;\n    text-align: left;\n    margin-bottom: 30px;\n    margin-left: 5px;\n}\n\n.custom-popper {\n    width: 825px !important;\n}\n\n.custom-popper-higher-width {\n    width: 1145px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
