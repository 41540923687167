// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-profile-information-content {
    margin-bottom: 128px;
    margin-top: 64px;
    text-align: center;
}

.seller-profile-information-content .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-size: 32px;
    line-height: 32px;
}

.seller-profile-information-content .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000000;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/CurrentАccount/Current%D0%90ccount.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,4BAA4B;IAC5B,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".seller-profile-information-content {\n    margin-bottom: 128px;\n    margin-top: 64px;\n    text-align: center;\n}\n\n.seller-profile-information-content .title {\n    margin-bottom: 16px;\n    font-family: Lora;\n    font-size: 32px;\n    line-height: 32px;\n}\n\n.seller-profile-information-content .button {\n    padding: 15px 32px;\n    border: 2px solid #00BCD4;\n    box-sizing: border-box;\n    border-radius: 4px;\n    color: #000000;\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 18px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
