// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-header-common {
    margin-bottom: 8px;
}

.result-header-common .result-title {
    float: left;
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.result-header-common .button-group {
    float: right;
}

.result-header-common .button-group .button {
    padding: 7px 12px;
    margin-left: 8px;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    cursor: pointer;
    border-radius: 4px;
    border: none;
}

.result-header-common .button-group .button span {
    margin-right: 7px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

@media screen and (max-width: 999px) {
    .result-header-common .result-title {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/WithoutLogin/CommonThirdParty/components/ResultHeader/ResultHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".result-header-common {\n    margin-bottom: 8px;\n}\n\n.result-header-common .result-title {\n    float: left;\n    margin-bottom: 8px;\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 24px;\n    color: #000000;\n}\n\n.result-header-common .button-group {\n    float: right;\n}\n\n.result-header-common .button-group .button {\n    padding: 7px 12px;\n    margin-left: 8px;\n    font-size: 16px;\n    line-height: 18px;\n    color: #000;\n    cursor: pointer;\n    border-radius: 4px;\n    border: none;\n}\n\n.result-header-common .button-group .button span {\n    margin-right: 7px;\n    font-family: Source Sans Pro;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 18px;\n    color: #000000;\n}\n\n@media screen and (max-width: 999px) {\n    .result-header-common .result-title {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
