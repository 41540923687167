import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Images from "../../assets/uploaded_images.json";
import { multiprofileDetails } from "../../features/common/mutiprofile.Slice";
import { filtersProperty } from "../../features/property/propertySlice";

import "./Footer.css";
import { getUserLocationDetailByName } from "../../hooks/useTranslator";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

import { useMediaQuery } from "react-responsive";

import instance from "../../config/axiosConfig";

/* Images */
const Logo = Images["Group 185.svg"];
const Instagram = Images["Instagram.svg"];
const Facebook = Images["Facebook.svg"];
const Linkedin = Images["Linkedin.svg"];

const mapState = ({ statisticsSlice }) => ({
    sentOffers: statisticsSlice.sentOffers,
    listedProperties: statisticsSlice.listedProperties,
});

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { sentOffers, listedProperties } = useSelector(mapState);

    const [userCountryCitiesWithNoProperties, setUserCountryCitiesWithNoProperties] = useState([]);
    const [residences, setResidences] = useState([]);

    const year = new Date().getFullYear();

    const activePersona = getLocalStorageItem("authenticatedUser") ? getLocalStorageItem("authenticatedUser")?.activePersona : null;
    const activePersonaDetails = getLocalStorageItem("activePersonaDetails") ? getLocalStorageItem("activePersonaDetails") : "";

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        getUserRecommendations();
    }, []);

    const handleRedirect = (value) => {
        dispatch(filtersProperty({
            municipality: value,
            address: value,
            neighborhood: "",
        }));

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        navigate("/search-properties");
    };

    const getUserRecommendations = () => {
        getUserLocationDetailByName("countryName").then(async (userCountry) => {
            let userCountryCities =
                userCountry === "Bulgaria"
                    ?   ["Sofia", "Plovdiv", "Ruse", "Stara Zagora", "Pleven", "Varna", "Burgas", "Sliven", "Dobrich", "Shumen"]
                    :   await getUserLocationDetailByName("userCountryCities");

            instance.post("/configurations/get-city-recommendations", {
                cities: userCountryCities,
            }).then(async ({ data }) => {
                let recommendedCities = data.citiesAndPropertyCounts;
                setResidences(recommendedCities);

                if (recommendedCities?.length === 0) {
                    userCountryCities?.slice(0, 10).forEach((city) => {
                        userCountryCities.push({ city, propertyCount: 0 });
                    });

                    setUserCountryCitiesWithNoProperties(userCountryCities);
                }
            });
        });
    };

    const handleOpenMultiProfile = () => {
        dispatch(multiprofileDetails({
            isOpenModal: true,
        }));
    };

    return (
        <footer id="footer" className="footer">
            <div className="footer-container">
                <div className="container">
                    {
                        isMobile
                            ?   <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3">
                                    <div className="col">
                                        <img loading="lazy" src={Logo} alt="" />
            
                                        <p className="paragraph"> {t("Всички права запазени")} {year} </p>

                                        <div className="col">
                                            <h3 className="footerHeaderTitles">{t("Полезни линкове")}</h3>
                
                                            <Link to="/general-terms" className="text links"> {t("Общи условия")} </Link>
                                            <Link to="/privacy-policy" className="text links"> {t("Политика за сигурност")} </Link>
                                            <Link to="/Cookies" className="text links"> {t("Политика за бисквитки")} </Link>
                                            <Link to="/about" className="text links"> {t("За нас")} </Link>
                                            <Link to="/pricing" className="text links"> {t("Ценообразуване")} </Link>
                                            <Link to="/how-to-buy" className="text links"> {t("Как да закупя имот?")} </Link>
                                            <Link to="/how-to-sell" className="text links"> {t("Как да продам имот?")} </Link>
                                        </div>

                                        <div className="col" style={{ marginBottom: '40px' }}>
                                            <p className="text-follow-us"> {t("Следвайте ни на")} </p>
                
                                            <div className="footerIcons">
                                                <a href="https://www.linkedin.com/company/abidors" className="icon" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                    <img src={Linkedin} alt="" className="icon" />
                                                </a>
                
                                                <a href="https://www.facebook.com/abidorsbrand" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                    <img src={Facebook} alt="" className="icon facebook" />
                                                </a>
                
                                                <a href="https://www.instagram.com/abidorsofficial" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                    <img src={Instagram} alt="" className="icon" />
                                                </a>
                                            </div>
                                        </div>
                
                                        <div
                                            className="col"
                                            style={{
                                                padding: 0,
                                                pointerEvents: (activePersona === "virtualassistant" || activePersona === "virtualAssistant") && !activePersonaDetails?.isCertified ? "none" : "auto",
                                                userSelect: (activePersona === "virtualassistant" || activePersona === "virtualAssistant") && !activePersonaDetails?.isCertified ? "none" : "auto",
                                            }}
                                        >
                                            <h3 className="footerHeaderTitles firstCol"> {residences?.length > 0 ? t("Намерете имоти в") : t("Очаквайте скоро имоти в:")} </h3>
                                            
                                            <div className="cols cols-full cols-no-gutter clearfix" style={{ marginBottom: '20px' }}>
                                                {
                                                    residences?.length > 0
                                                        ?   residences?.map((item, i) => {
                                                                return <h3 key={`${item?.city}${i}`} className="cities col col6of12" onClick={() => handleRedirect(item?.city)}>
                                                                    {t(item?.city)}
                                                                    {` ${item?.propertyCount && item?.propertyCount>0 ? `(${item?.propertyCount})` : ""}`}
                                                                </h3>
                                                            })
                                                        :   userCountryCitiesWithNoProperties?.length
                                                                ?   userCountryCitiesWithNoProperties?.map((item, i) => {
                                                                        return item?.city
                                                                            ?   <h3 key={`${item?.city}${i}`} className="cities col col6of12" onClick={() => handleRedirect(item?.city)}>
                                                                                    {t(item?.city)}
                                                                                    {` ${item?.propertyCount && item?.propertyCount>0 ? `(${item?.propertyCount})` : ""}`}
                                                                                </h3>
                                                                            :   null
                                                                    })
                                                                :   null
                                                }
                                            </div>
                                        </div>
            
                                        {(sentOffers > 500 || listedProperties > 500) && (
                                            <h3 className="footerHeaderTitles thirdCol">
                                                {t("Световна статистика от стартирането на системата")}
                                            </h3>
                                        )}
            
                                        {sentOffers > 500 && (
                                            <p className="text">
                                                <strong> {sentOffers} </strong>
                                                {sentOffers > 500 ? `${t("Изпратени оферти")}` : `${t("Изпратена оферта")}`}
                                            </p>
                                        )}
            
                                        {listedProperties > 500 && (
                                            <p className="text">
                                                <strong> {listedProperties} </strong> 
                                                {t("Публикувани")}{" "}
                                                {sentOffers !== 500 ? `${t("имота")}` : `${t("имот")}`}
                                            </p>
                                        )}

                                        <div className="buttons" style={{ margin: '0 auto' }}>
                                            <Button
                                                className="button w-md-50 w-auto responsive"
                                                style={{ marginTop: sentOffers > 1 || listedProperties > 1 ? "40px" : "0px" }}
                                                onClick={() => navigate("/search-properties")}
                                            >
                                                {t("Търсене на имот")}
                                            </Button>
                                            
                                            {activePersona !== "virtualassistant" &&
                                                activePersona !== "virtualAssistant" ? (
                                                <div className="button-group">
                                                    <Button className="button responsive-virtual-assistant w-md-50 w-auto" onClick={handleOpenMultiProfile}> {t("Стани виртуален асистент")} </Button>
                                                </div>
                                            ) : (
                                                <Button className="button responsive-virtual-assistant w-md-50 w-auto" onClick={handleOpenMultiProfile}> {t("Мулти-профил")} </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            :   <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3">
                                    <div className="col">
                                        <img loading="lazy" src={Logo} alt="" />
            
                                        <p className="paragraph"> {t("Всички права запазени")} {year} </p>
            
                                        <div
                                            className="col"
                                            style={{
                                                padding: 0,
                                                pointerEvents: (activePersona === "virtualassistant" || activePersona === "virtualAssistant") && !activePersonaDetails?.isCertified ? "none" : "auto",
                                                userSelect: (activePersona === "virtualassistant" || activePersona === "virtualAssistant") && !activePersonaDetails?.isCertified ? "none" : "auto",
                                            }}
                                        >
                                            <h3 className="footerHeaderTitles firstCol"> {residences?.length > 0 ? t("Намерете имоти в") : t("Очаквайте скоро имоти в:")} </h3>
                                            
                                            <div className="cols cols-full cols-no-gutter clearfix" style={{ marginBottom: '20px' }}>
                                                {
                                                    residences?.length > 0
                                                        ?   residences?.map((item, i) => {
                                                                return <h3 key={`${item?.city}${i}`} className="cities col col12of12" onClick={() => handleRedirect(item?.city)}>
                                                                    {t(item?.city)}
                                                                    {` ${item?.propertyCount && item?.propertyCount>0 ? `(${item?.propertyCount})` : ""}`}
                                                                </h3>
                                                            })
                                                        :   userCountryCitiesWithNoProperties?.length
                                                                ?   userCountryCitiesWithNoProperties?.map((item, i) => {
                                                                        return item?.city
                                                                            ?   <h3 key={`${item?.city}${i}`} className="cities col col12of12" onClick={() => handleRedirect(item?.city)}>
                                                                                    {t(item?.city)}
                                                                                    {` ${item?.propertyCount && item?.propertyCount > 0 ? `(${item?.propertyCount})` : ""}`}
                                                                                </h3>
                                                                            :   null
                                                                        
                                                                    })
                                                                :   null
                                                }
                                            </div>
                                        </div>
            
                                        {(sentOffers > 500 || listedProperties > 500) && (
                                            <h3 className="footerHeaderTitles thirdCol">
                                                {t("Световна статистика от стартирането на системата")}
                                            </h3>
                                        )}
            
                                        {sentOffers > 500 && (
                                            <p className="text">
                                                <strong> {sentOffers} </strong>
                                                {sentOffers > 500 ? `${t("Изпратени оферти")}` : `${t("Изпратена оферта")}`}
                                            </p>
                                        )}
            
                                        {listedProperties > 500 && (
                                            <p className="text">
                                                <strong> {listedProperties} </strong> 
                                                {t("Публикувани")}{" "}
                                                {sentOffers !== 500 ? `${t("имота")}` : `${t("имот")}`}
                                            </p>
                                        )}
                                    </div>
            
                                    <div className="col">
                                        <h3 className="footerHeaderTitles">{t("Полезни линкове")}</h3>
            
                                        <Link to="/general-terms" className="text links"> {t("Общи условия")} </Link>
                                        <Link to="/privacy-policy" className="text links"> {t("Политика за сигурност")} </Link>
                                        <Link to="/Cookies" className="text links"> {t("Политика за бисквитки")} </Link>
                                        <Link to="/about" className="text links"> {t("За нас")} </Link>
                                        <Link to="/pricing" className="text links"> {t("Ценообразуване")} </Link>
                                        <Link to="/blog" className="text links"> {t("Блог")} </Link>
                                        <Link to="/how-to-buy" className="text links"> {t("Как да закупя имот?")} </Link>
                                        <Link to="/how-to-sell" className="text links"> {t("Как да продам имот?")} </Link>
                                    </div>
            
                                    <div className="col">
                                        <p className="text-follow-us"> {t("Следвайте ни на")} </p>
            
                                        <div className="footerIcons">
                                            <a href="https://www.linkedin.com/company/abidors" className="icon" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                <img src={Linkedin} alt="" className="icon" />
                                            </a>
            
                                            <a href="https://www.facebook.com/abidorsbrand" className="facebook-div" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                <img src={Facebook} alt="" className="icon facebook" />
                                            </a>
            
                                            <a href="https://www.instagram.com/abidorsofficial" style={{ color: "#fff" }} target="_blank" rel="noreferrer">
                                                <img src={Instagram} alt="" className="icon" />
                                            </a>
                                        </div>
            
                                        <div className="buttons">
                                            <Button
                                                className="button w-md-50 w-auto"
                                                style={{ marginTop: sentOffers > 1 || listedProperties > 1 ? "40px" : "0px" }}
                                                onClick={() => navigate("/search-properties")}
                                            >
                                                {t("Търсене на имот")}
                                            </Button>
                                            
                                            {activePersona !== "virtualassistant" &&
                                                activePersona !== "virtualAssistant" ? (
                                                <div className="button-group mb-4 mt-md-2 mb-md-5 mb-sm-4">
                                                    <Button className="button" onClick={handleOpenMultiProfile}> {t("Стани виртуален асистент")} </Button>
                                                </div>
                                            ) : (
                                                <Button className="button" onClick={handleOpenMultiProfile}> {t("Мулти-профил")} </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                    }
                </div>
            </div>
        </footer>
    );
};

export default Footer;