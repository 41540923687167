// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lawyer-feature-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
}
.lawyer-feature-item > img {
  margin-bottom: 16px;
  width: 76px;
  height: 77px;
}
.lawyer-feature-item > p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 16px;
}
.lawyer-feature-item > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 44px;
  color: #000000;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px;
}
.lawyer-feature-item > span > span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/pages/Lawyer/MyProfile/components/LawyerServices/ServiceItem/ServiceItem.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;EACA,WAAA;EACA,YAAA;AAER;AAAI;EACI,4BAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AAER;AAAI;EACI,4BAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAER;AADQ;EACI,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;AAGZ","sourcesContent":[".lawyer-feature-item {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    background: #f8f8f8;\n    border-radius: 8px;\n    padding: 24px;\n    > img {\n        margin-bottom: 16px;\n        width: 76px;\n        height: 77px;\n    }\n    > p {\n        font-family: Source Sans Pro;\n        font-style: normal;\n        font-weight: bold;\n        font-size: 20px;\n        line-height: 24px;\n        text-transform: uppercase;\n        color: #000000;\n        margin-bottom: 16px;\n    }\n    > span {\n        font-family: Source Sans Pro;\n        font-style: normal;\n        font-weight: bold;\n        font-size: 48px;\n        line-height: 44px;\n        color: #000000;\n        background: #FFFFFF;\n        border-radius: 4px;\n        padding: 8px;\n        > span {\n            font-family: Source Sans Pro;\n            font-style: normal;\n            font-weight: 900;\n            font-size: 18px;\n            line-height: 22px;\n            text-transform: uppercase;\n            color: #000000;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
