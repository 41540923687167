// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }
.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }
/* .MuiStepConnector-alternativeLabel { border-top-width: 6px !important; } 
.MuiStepConnector-alternativeLabel.Mui-active { background: #A5D6A7 !important; } 
.MuiStepConnector-alternativeLabel.Mui-disabled {  } */`, "",{"version":3,"sources":["webpack://./src/components/common/StepProgressBar/StepProgressBar.css"],"names":[],"mappings":"AAAA,+BAA+B,yBAAyB,EAAE;AAC1D,kCAAkC,yBAAyB,EAAE;AAC7D;;sDAEsD","sourcesContent":[".MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }\n.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }\n/* .MuiStepConnector-alternativeLabel { border-top-width: 6px !important; } \n.MuiStepConnector-alternativeLabel.Mui-active { background: #A5D6A7 !important; } \n.MuiStepConnector-alternativeLabel.Mui-disabled {  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
