// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vip-place .text { margin-top: 8px; margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.vip-place .box-content { display: flex; }
.vip-place .box-content .box { margin-right: 32px; text-align: center; background: #FFFFFF; border-radius: 4px; }
.vip-place .box-content .box:last-child { margin-right: 0; }
.vip-place .box-content .box .icon { width: 76px; height: 60px; margin: 39px 65px 23px 65px; }
.vip-place .box-content .box .text { margin: 0px 24px 24px 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.vip-place .button { padding: 15px 32px; margin: 24px 0 64px 0; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }`, "",{"version":3,"sources":["webpack://./src/pages/Photographer/VipProfile/VipPlace/HideVipPlace/HideVipPlace.css"],"names":[],"mappings":"AAAA,mBAAmB,eAAe,EAAE,mBAAmB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AACpK,0BAA0B,aAAa,EAAE;AACzC,+BAA+B,kBAAkB,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,kBAAkB,EAAE;AAChH,0CAA0C,eAAe,EAAE;AAC3D,qCAAqC,WAAW,EAAE,YAAY,EAAE,2BAA2B,EAAE;AAC7F,qCAAqC,0BAA0B,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC5K,qBAAqB,kBAAkB,EAAE,qBAAqB,EAAE,mBAAmB,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,cAAc,EAAE","sourcesContent":[".vip-place .text { margin-top: 8px; margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }\n.vip-place .box-content { display: flex; }\n.vip-place .box-content .box { margin-right: 32px; text-align: center; background: #FFFFFF; border-radius: 4px; }\n.vip-place .box-content .box:last-child { margin-right: 0; }\n.vip-place .box-content .box .icon { width: 76px; height: 60px; margin: 39px 65px 23px 65px; }\n.vip-place .box-content .box .text { margin: 0px 24px 24px 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }\n.vip-place .button { padding: 15px 32px; margin: 24px 0 64px 0; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
