// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-blob { padding: 13px 15px; text-align: left; background: #F2FEFF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 16px; }
.chat-blob.waiting { background: #FAF4F1; }
.chat-blob.send { background: #9D9D9D; }
.chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.chat-blob .blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.chat-blob .blob-header .title { font-size: 14px; line-height: 18px; font-weight: 700; font-family: 'Source Sans Pro'; margin-left: 10px; text-transform: uppercase; }
.chat-blob .blob-header .hour { font-size: 12px; line-height: 12px; font-family: 'Source Sans Pro'; font-weight: 400; margin-left: 10px; }
.chat-blob .text { font-size: 16px; line-height: 18px; font-family: 'Source Sans Pro'; font-weight: 400; }
`, "",{"version":3,"sources":["webpack://./src/components/common/ChatBox/ChatBlob/ChatBlob.css"],"names":[],"mappings":"AAAA,aAAa,kBAAkB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE,iCAAiC,EAAE,YAAY,EAAE,mBAAmB,EAAE;AAC9I,qBAAqB,mBAAmB,EAAE;AAC1C,kBAAkB,mBAAmB,EAAE;AACvC,0BAA0B,aAAa,EAAE,mBAAmB,EAAE,kBAAkB,EAAE;AAClF,kCAAkC,WAAW,EAAE,YAAY,EAAE,kBAAkB,EAAE;AACjF,iCAAiC,eAAe,EAAE,iBAAiB,EAAE,gBAAgB,EAAE,8BAA8B,EAAE,iBAAiB,EAAE,yBAAyB,EAAE;AACrK,gCAAgC,eAAe,EAAE,iBAAiB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE,iBAAiB,EAAE;AACzI,mBAAmB,eAAe,EAAE,iBAAiB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE","sourcesContent":[".chat-blob { padding: 13px 15px; text-align: left; background: #F2FEFF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 16px; }\n.chat-blob.waiting { background: #FAF4F1; }\n.chat-blob.send { background: #9D9D9D; }\n.chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }\n.chat-blob .blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }\n.chat-blob .blob-header .title { font-size: 14px; line-height: 18px; font-weight: 700; font-family: 'Source Sans Pro'; margin-left: 10px; text-transform: uppercase; }\n.chat-blob .blob-header .hour { font-size: 12px; line-height: 12px; font-family: 'Source Sans Pro'; font-weight: 400; margin-left: 10px; }\n.chat-blob .text { font-size: 16px; line-height: 18px; font-family: 'Source Sans Pro'; font-weight: 400; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
