// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
}
.feature-item > img {
  margin-top: 40px;
  margin-bottom: 15px;
  width: 76px;
  height: 77px;
}
.feature-item > h5 {
  margin: 0;
  padding: 0;
  font-family: "Lora", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  margin: 8px 0;
}
.feature-item > p {
  max-width: 374px;
  text-align: center;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/Features/FeatureItem/FeatureItem.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAAI;EACI,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAER;AAAI;EACI,SAAA;EACA,UAAA;EACA,+BAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;AAER;AAAI;EACI,gBAAA;EACA,kBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAER","sourcesContent":[".feature-item {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    background: #f8f8f8;\n    border-radius: 8px;\n    padding: 20px;\n    > img {\n        margin-top: 40px;\n        margin-bottom: 15px;\n        width: 76px;\n        height: 77px;\n    }\n    > h5 {\n        margin: 0;\n        padding: 0;\n        font-family: 'Lora', sans-serif;\n        font-style: normal;\n        font-weight: bold;\n        font-size: 24px;\n        line-height: 26px;\n        text-align: center;\n        color: #000000;\n        margin: 8px 0;\n    }\n    > p {\n        max-width: 374px;\n        text-align: center;\n        font-family: 'Source Sans Pro';\n        font-style: normal;\n        font-weight: normal;\n        font-size: 18px;\n        line-height: 24px;\n        text-align: center;\n        color: #000000;\n        margin-bottom: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
