// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buyer-contract-body { padding: 24px; background: #F4F4F4; border-radius: 12px; text-align: left; height: 410px; overflow: auto; }
.buyer-contract-body .title { margin: 0 0 32px 0; font-size: 36px; line-height: 32px; }
.buyer-contract-body .common-title { margin-bottom: 10px; font-size: 24px; line-height: 32px; }
.buyer-contract-body .text { margin-bottom: 32px; font-size: 18px; line-height: 32px; }`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Contract/ContractBody/ContractBody.css"],"names":[],"mappings":"AAAA,uBAAuB,aAAa,EAAE,mBAAmB,EAAE,mBAAmB,EAAE,gBAAgB,EAAE,aAAa,EAAE,cAAc,EAAE;AACjI,8BAA8B,kBAAkB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AACtF,qCAAqC,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC9F,6BAA6B,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE","sourcesContent":[".buyer-contract-body { padding: 24px; background: #F4F4F4; border-radius: 12px; text-align: left; height: 410px; overflow: auto; }\n.buyer-contract-body .title { margin: 0 0 32px 0; font-size: 36px; line-height: 32px; }\n.buyer-contract-body .common-title { margin-bottom: 10px; font-size: 24px; line-height: 32px; }\n.buyer-contract-body .text { margin-bottom: 32px; font-size: 18px; line-height: 32px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
