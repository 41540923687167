// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meeting-box-with-history { padding: 16px 16px 24px 16px; background: #FFFFFF; border-radius: 4px; }
.meeting-box-with-history .text-with-icon { display: flex; align-items: center; margin-bottom: 16px; }
.meeting-box-with-history .text-with-icon:first-child { margin-top: 3px; }
.meeting-box-with-history .text-with-icon .icon { margin-right: 10px; margin-top: -2px; }
.meeting-box-with-history .text-with-icon .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.meeting-box-with-history .button-change { height: 40px; padding: 11px 16px; border-radius: 4px; background: #00BCD4; color: #fff; margin-bottom: 11px; font-size: 14px; line-height: 18px; font-family: Roboto; font-weight: 400; text-transform: uppercase; }
.meeting-box-with-history .button-cancel { height: 40px; width: 100px; padding: 8px; color: #0097A7; font-size: 18px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.meeting-box-with-history .button-cancel:hover { background: #fff; }
`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/PhotographerServices/HeaderContent/AppointmentSet/AppointmentSet.css"],"names":[],"mappings":"AAAA,4BAA4B,4BAA4B,EAAE,mBAAmB,EAAE,kBAAkB,EAAE;AACnG,4CAA4C,aAAa,EAAE,mBAAmB,EAAE,mBAAmB,EAAE;AACrG,wDAAwD,eAAe,EAAE;AACzE,kDAAkD,kBAAkB,EAAE,gBAAgB,EAAE;AACxF,kDAAkD,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE;AACtI,2CAA2C,YAAY,EAAE,kBAAkB,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,WAAW,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,mBAAmB,EAAE,gBAAgB,EAAE,yBAAyB,EAAE;AAC9P,2CAA2C,YAAY,EAAE,YAAY,EAAE,YAAY,EAAE,cAAc,EAAE,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE,yBAAyB,EAAE;AACpN,iDAAiD,gBAAgB,EAAE","sourcesContent":[".meeting-box-with-history { padding: 16px 16px 24px 16px; background: #FFFFFF; border-radius: 4px; }\n.meeting-box-with-history .text-with-icon { display: flex; align-items: center; margin-bottom: 16px; }\n.meeting-box-with-history .text-with-icon:first-child { margin-top: 3px; }\n.meeting-box-with-history .text-with-icon .icon { margin-right: 10px; margin-top: -2px; }\n.meeting-box-with-history .text-with-icon .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }\n.meeting-box-with-history .button-change { height: 40px; padding: 11px 16px; border-radius: 4px; background: #00BCD4; color: #fff; margin-bottom: 11px; font-size: 14px; line-height: 18px; font-family: Roboto; font-weight: 400; text-transform: uppercase; }\n.meeting-box-with-history .button-cancel { height: 40px; width: 100px; padding: 8px; color: #0097A7; font-size: 18px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }\n.meeting-box-with-history .button-cancel:hover { background: #fff; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
