// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonUnFilled {
  left: 198px;
  top: 614px;
  border-radius: 4px;
  padding: 13px 32px 13px 32px;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  background-color: transparent;
  text-transform: uppercase;
}
.buttonUnFilled > span > img {
  margin-left: 7px;
  width: 17px;
  height: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Seller/components/ButtonUnFilled/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,4BAA4B;EAC5B,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,6BAA6B;EAC7B,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd","sourcesContent":[".buttonUnFilled {\n  left: 198px;\n  top: 614px;\n  border-radius: 4px;\n  padding: 13px 32px 13px 32px;\n  border: 2px solid #00bcd4;\n  font-family: Source Sans Pro;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 18px;\n  cursor: pointer;\n  background-color: transparent;\n  text-transform: uppercase;\n}\n.buttonUnFilled > span > img {\n  margin-left: 7px;\n  width: 17px;\n  height: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
