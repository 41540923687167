// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-seller-join-meeting { text-align: center; }
.dialog-seller-join-meeting-content { padding: 64px 120px; }
.dialog-seller-join-meeting-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-seller-join-meeting-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-seller-join-meeting-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-seller-join-meeting-content .content { padding: 0; overflow-x: hidden; }
.dialog-seller-join-meeting-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro; }

.dialog-seller-join-meeting-content .content .button-join { height: 45px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
`, "",{"version":3,"sources":["webpack://./src/pages/Seller/MyProfile/VirtualAssistant/JoinMeeting/JoinMeeting.css"],"names":[],"mappings":"AAAA,8BAA8B,kBAAkB,EAAE;AAClD,sCAAsC,mBAAmB,EAAE;AAC3D,oDAAoD,UAAU,EAAE,kBAAkB,EAAE;AACpF,2DAA2D,qBAAqB,EAAE,eAAe,EAAE,iBAAiB,EAAE,iBAAiB,EAAE,gBAAgB,EAAE;AAC3J,gEAAgE,kBAAkB,EAAE,SAAS,EAAE,WAAW,EAAE,eAAe,EAAE;;AAE7H,+CAA+C,UAAU,EAAE,kBAAkB,EAAE;AAC/E,0DAA0D,eAAe,EAAE,iBAAiB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE,4BAA4B,EAAE;;AAEnK,4DAA4D,YAAY,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,kBAAkB,EAAE,WAAW,EAAE,yBAAyB,EAAE,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE","sourcesContent":[".dialog-seller-join-meeting { text-align: center; }\n.dialog-seller-join-meeting-content { padding: 64px 120px; }\n.dialog-seller-join-meeting-content .title-header { padding: 0; margin-bottom: 8px; }\n.dialog-seller-join-meeting-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }\n.dialog-seller-join-meeting-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }\n\n.dialog-seller-join-meeting-content .content { padding: 0; overflow-x: hidden; }\n.dialog-seller-join-meeting-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro; }\n\n.dialog-seller-join-meeting-content .content .button-join { height: 45px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
