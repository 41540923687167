// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-offered { text-align: left; }
.services-offered .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; color: #000000; }`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/PhotographerServices/ServicesOffered/ServicesOffered.css"],"names":[],"mappings":"AAAA,oBAAoB,gBAAgB,EAAE;AACtC,2BAA2B,iBAAiB,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE","sourcesContent":[".services-offered { text-align: left; }\n.services-offered .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; color: #000000; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
