// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.contactPage .content { margin: 0 384px 64px; }
.contactPage .content form { text-align: center; }
.contactPage .content .title-sub { margin-bottom: 64px; font-size: 18px; line-height: 24px; text-align: center; font-family: Source Sans Pro; font-weight: 400; }
.contactPage .content .textField { margin-bottom: 36px; background-color:#EDEDED; border-radius: 4px 4px 0px 0px; }
.contactPage .content .textField:last-child { margin-bottom: 24px !important; }

.contactPage .content .button { display: inline-block; padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;}

@media screen and (max-width: 1124px) {
  .contactPage .content { 
    margin:0 25% 84px !important; 
  }
}
@media screen and (max-width: 600px) {
  .contactPage .content { 
    margin:0 15% 84px !important; 
  }
}

@media screen and (max-width: 350px) {
  .contactPage .content { 
    margin:0 10% 84px !important; 
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/SecondaryPages/Contact/Contact.css"],"names":[],"mappings":";AACA,wBAAwB,oBAAoB,EAAE;AAC9C,6BAA6B,kBAAkB,EAAE;AACjD,mCAAmC,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE;AAChK,mCAAmC,mBAAmB,EAAE,wBAAwB,EAAE,8BAA8B,EAAE;AAClH,8CAA8C,8BAA8B,EAAE;;AAE9E,gCAAgC,qBAAqB,EAAE,kBAAkB,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,WAAW,EAAE,yBAAyB,EAAE,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,CAAC;;AAErP;EACE;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["\n.contactPage .content { margin: 0 384px 64px; }\n.contactPage .content form { text-align: center; }\n.contactPage .content .title-sub { margin-bottom: 64px; font-size: 18px; line-height: 24px; text-align: center; font-family: Source Sans Pro; font-weight: 400; }\n.contactPage .content .textField { margin-bottom: 36px; background-color:#EDEDED; border-radius: 4px 4px 0px 0px; }\n.contactPage .content .textField:last-child { margin-bottom: 24px !important; }\n\n.contactPage .content .button { display: inline-block; padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;}\n\n@media screen and (max-width: 1124px) {\n  .contactPage .content { \n    margin:0 25% 84px !important; \n  }\n}\n@media screen and (max-width: 600px) {\n  .contactPage .content { \n    margin:0 15% 84px !important; \n  }\n}\n\n@media screen and (max-width: 350px) {\n  .contactPage .content { \n    margin:0 10% 84px !important; \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
