// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
    display: grid;
    place-content: center;
    height: 100vh;
  }
  
  .loading-image {
    animation: bounce 2s infinite;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LazyWrapper/LazyWrapper.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,6BAA6B;IAC7B,aAAa;IACb,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".loading-container {\n    display: grid;\n    place-content: center;\n    height: 100vh;\n  }\n  \n  .loading-image {\n    animation: bounce 2s infinite;\n    height: 100px;\n    width: 100px;\n    object-fit: cover;\n  }\n  \n  @keyframes bounce {\n    0%, 20%, 50%, 80%, 100% {\n      transform: translateY(0);\n    }\n    40% {\n      transform: translateY(-10px);\n    }\n    60% {\n      transform: translateY(-5px);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
