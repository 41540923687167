// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-service-details { display: flex; flex-direction: row; }
.confirm-service-details .icon { margin-right: 18px; align-self: flex-start; }
.confirm-service-details .current-details { display: flex; flex-direction: column;width:100%}
.confirm-service-details .current-details .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.confirm-service-details .current-details .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.confirm-service-details .current-details .right-part { margin-top: 5px;width:100%}

@media screen and (max-width:765px){
    .confirm-service-details {
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width:530px){
    .confirm-service-details .current-details {
        flex-direction: column;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Lawyer/LawyerServices/ServiceConfirmation/ServiceConfirmation.css"],"names":[],"mappings":"AAAA,2BAA2B,aAAa,EAAE,mBAAmB,EAAE;AAC/D,iCAAiC,kBAAkB,EAAE,sBAAsB,EAAE;AAC7E,4CAA4C,aAAa,EAAE,sBAAsB,CAAC,UAAU;AAC5F,8DAA8D,kBAAkB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAChL,6DAA6D,mBAAmB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC7L,wDAAwD,eAAe,CAAC,UAAU;;AAElF;IACI;QACI,mBAAmB;QACnB,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":[".confirm-service-details { display: flex; flex-direction: row; }\n.confirm-service-details .icon { margin-right: 18px; align-self: flex-start; }\n.confirm-service-details .current-details { display: flex; flex-direction: column;width:100%}\n.confirm-service-details .current-details .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }\n.confirm-service-details .current-details .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }\n.confirm-service-details .current-details .right-part { margin-top: 5px;width:100%}\n\n@media screen and (max-width:765px){\n    .confirm-service-details {\n        flex-direction: row;\n        align-items: center;\n    }\n}\n\n@media screen and (max-width:530px){\n    .confirm-service-details .current-details {\n        flex-direction: column;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
