// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-switcher {
    height: 100%;
}

.lang-switcher select {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    background-color:rgb(237, 237, 237);
}

@media only screen and (max-width: 1000px) {
    .lang-switcher {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 765px) {
    .lang-switcher {
        margin: 0;
        margin-right: 5px;
        width: 40px;
        margin-top: -6px;
    }
    .lang-switcher select{
        height: 39px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/LangSwitcher/LangSwitcher.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,aAAa;IACb,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,SAAS;QACT,iBAAiB;QACjB,WAAW;QACX,gBAAgB;IACpB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".lang-switcher {\n    height: 100%;\n}\n\n.lang-switcher select {\n    width: 100%;\n    height: 100%;\n    border: none;\n    cursor: pointer;\n    outline: none;\n    padding: 0 10px;\n    font-size: 12px;\n    font-weight: 500;\n    border-radius: 5px;\n    background-color:rgb(237, 237, 237);\n}\n\n@media only screen and (max-width: 1000px) {\n    .lang-switcher {\n        margin: 0 20px;\n    }\n}\n\n@media only screen and (max-width: 765px) {\n    .lang-switcher {\n        margin: 0;\n        margin-right: 5px;\n        width: 40px;\n        margin-top: -6px;\n    }\n    .lang-switcher select{\n        height: 39px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
