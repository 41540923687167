// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quastion-container { margin: 0 267px 128px 267px; }
.quastion-container .MuiPaper-elevation1 { box-shadow: none; }
.quastion-container .title { margin-bottom: 12px; font-size: 20px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.quastion-container .text { margin-bottom: 42px; font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }`, "",{"version":3,"sources":["webpack://./src/pages/SecondaryPages/FrequentlyQuastion/FrequentlyQuastion.css"],"names":[],"mappings":"AAAA,sBAAsB,2BAA2B,EAAE;AACnD,2CAA2C,gBAAgB,EAAE;AAC7D,6BAA6B,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE,yBAAyB,EAAE;AACjK,4BAA4B,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,4BAA4B,EAAE,gBAAgB,EAAE","sourcesContent":[".quastion-container { margin: 0 267px 128px 267px; }\n.quastion-container .MuiPaper-elevation1 { box-shadow: none; }\n.quastion-container .title { margin-bottom: 12px; font-size: 20px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }\n.quastion-container .text { margin-bottom: 42px; font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
