// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virtual-assistant-features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 64px 0px 64px 0px;
}
.virtual-assistant-features > h3 {
  font-family: "Lora", sans-serif;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.virtual-assistant-features-items {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .virtual-assistant-features-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .virtual-assistant-features-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 650px) {
  .virtual-assistant-features-items {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .virtual-assistant-features-items {
    grid-template-columns: repeat(1, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/Features/Features.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AACJ;AAAI;EACI,+BAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,qCAAA;EACA,SAAA;EACA,WAAA;AAER;;AAEA;EAGQ;IACI,qCAAA;EADV;AACF;AAKA;EAGQ;IACI,qCAAA;EALV;AACF;AASA;EAGQ;IACI,qCAAA;EATV;AACF;AAaA;EAGQ;IACI,qCAAA;EAbV;AACF","sourcesContent":[".virtual-assistant-features {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    margin:64px 0px 64px 0px;\n    > h3 {\n        font-family: 'Lora', sans-serif;\n        font-style: normal;\n        font-weight: 1;\n        font-size: 48px;\n        line-height: 52px;\n        text-align: center;\n        color: #000000;\n        margin-bottom: 20px;\n    }\n    &-items {\n        display: grid;\n        grid-template-columns: auto auto auto;\n        gap: 30px;\n        width: 100%;\n    }\n}\n\n@media screen and (max-width:900px){\n    .virtual-assistant-features {\n       \n        &-items {\n            grid-template-columns: repeat(2, 1fr);\n        }\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .virtual-assistant-features {\n       \n        &-items {\n            grid-template-columns: repeat(2, 1fr);\n        }\n    }\n}\n\n@media screen and (max-width: 650px) {\n    .virtual-assistant-features {\n       \n        &-items {\n            grid-template-columns: repeat(1,1fr);\n        }\n    }\n}\n\n@media screen and (max-width: 500px) {\n    .virtual-assistant-features {\n       \n        &-items {\n            grid-template-columns: repeat(1, 1fr);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
