// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-test { padding: 204px 384px 204px 384px; margin: 0 auto; text-align: center}
.success-test img { margin-bottom: 16px; width: 76px; height: 76px; }
.success-test .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.success-test .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.success-test .link { padding: 12px 32px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #fff; }
.success-test .link:hover { background: #00BCD4; }

@media screen and (max-width:1040px){
.success-test { 
    padding: 100px 10px; 
}
}

@media screen and (max-width:400px){
    .success-test .title {
         margin-bottom: 8px; 
         font-family: Lora; 
         font-size: 30px; 
         line-height: 52px; 
        }

}`, "",{"version":3,"sources":["webpack://./src/pages/VirtualAssistant/ExamTest/SuccessTest/SuccessTest.css"],"names":[],"mappings":"AAAA,gBAAgB,gCAAgC,EAAE,cAAc,EAAE,kBAAkB;AACpF,oBAAoB,mBAAmB,EAAE,WAAW,EAAE,YAAY,EAAE;AACpE,uBAAuB,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAClG,2BAA2B,mBAAmB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AAC3J,sBAAsB,kBAAkB,EAAE,eAAe,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,WAAW,EAAE;AACzQ,4BAA4B,mBAAmB,EAAE;;AAEjD;AACA;IACI,mBAAmB;AACvB;AACA;;AAEA;IACI;SACK,kBAAkB;SAClB,iBAAiB;SACjB,eAAe;SACf,iBAAiB;QAClB;;AAER","sourcesContent":[".success-test { padding: 204px 384px 204px 384px; margin: 0 auto; text-align: center}\n.success-test img { margin-bottom: 16px; width: 76px; height: 76px; }\n.success-test .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }\n.success-test .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }\n.success-test .link { padding: 12px 32px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #fff; }\n.success-test .link:hover { background: #00BCD4; }\n\n@media screen and (max-width:1040px){\n.success-test { \n    padding: 100px 10px; \n}\n}\n\n@media screen and (max-width:400px){\n    .success-test .title {\n         margin-bottom: 8px; \n         font-family: Lora; \n         font-size: 30px; \n         line-height: 52px; \n        }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
