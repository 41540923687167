// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photographer-services .step-details { margin-bottom: 24px; border-radius: 12px; padding: 16px; }

.photographer-services .toggle-steps { margin-bottom: 24px; background: #F2FEFF; border-radius: 12px; padding: 16px; text-align: center; }
.photographer-services .toggle-steps .button-next { margin-right: 32px; background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.photographer-services .toggle-steps .button-previous { margin-right: 32px; border: 2px solid #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/PhotographerServices/PhotographerServices.css"],"names":[],"mappings":"AAAA,uCAAuC,mBAAmB,EAAE,mBAAmB,EAAE,aAAa,EAAE;;AAEhG,uCAAuC,mBAAmB,EAAE,mBAAmB,EAAE,mBAAmB,EAAE,aAAa,EAAE,kBAAkB,EAAE;AACzI,oDAAoD,kBAAkB,EAAE,mBAAmB,EAAE,kBAAkB,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,cAAc,EAAE;AACzR,wDAAwD,kBAAkB,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,kBAAkB,EAAE,4BAA4B,EAAE,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE,yBAAyB,EAAE,cAAc,EAAE","sourcesContent":[".photographer-services .step-details { margin-bottom: 24px; border-radius: 12px; padding: 16px; }\n\n.photographer-services .toggle-steps { margin-bottom: 24px; background: #F2FEFF; border-radius: 12px; padding: 16px; text-align: center; }\n.photographer-services .toggle-steps .button-next { margin-right: 32px; background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }\n.photographer-services .toggle-steps .button-previous { margin-right: 32px; border: 2px solid #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
