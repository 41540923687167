// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-menu {
    padding: 20px;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/VirtualTour/components/sidebarMenus/sidebarMenus.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".sidebar-menu {\n    padding: 20px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
