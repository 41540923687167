// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-virtual-assistant {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


@media screen and (max-width:500px){
    .profile-virtual-assistant {
        padding: 0px;
    }

    .profile-lawyer-relationship-suggestion {
        margin: 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Buyer/MyProfile/VirtualAssistant/VirtualAssistant.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;AAC3B;;;AAGA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".profile-virtual-assistant {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n}\n\n\n@media screen and (max-width:500px){\n    .profile-virtual-assistant {\n        padding: 0px;\n    }\n\n    .profile-lawyer-relationship-suggestion {\n        margin: 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
