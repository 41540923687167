import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { setLocalStorageItem } from "./config/localStorageEncryption";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUDGET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

console.log("================================================config",firebaseConfig)

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

let messaging = null;

// Check if service workers are supported
if ('serviceWorker' in navigator) {
  messaging = getMessaging(app);
}

export const fetchToken = async (setFcmToken) => {
  if (!messaging) {
    console.log("Service Workers are not supported in this environment.");
    return;
  }

  const maxRetries = 5;
  let attempts = 0;
  let token = null;

  // Wait for the service worker to be fully ready
  const serviceWorkerReady = await navigator.serviceWorker.ready;

  while (attempts < maxRetries && !token) {
    try {
      // Attempt to get the token
      token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIKEY });

      if (token) {
        setFcmToken(token);
        setLocalStorageItem("currentTokenNotification", token);
      } else {
        setFcmToken(token);
        localStorage.setItem("currentTokenNotification", "");
      }
    } catch (err) {
      attempts++;
      // Optional: Add a small delay before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
  });
