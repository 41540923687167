// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addMovement-wrapper {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border-radius: 4px;
}
.addMovement-wrapper > .top {
  width: calc(100% - 32px);
  margin-top: 16px;
  height: 50px;
  z-index: 500;
}
.addMovement-wrapper > .top > h1 {
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 16px;
}
.addMovement-wrapper > .top > p {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  padding: 3px 8px;
  width: fit-content;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.addMovement-wrapper > .top > .highlighted {
  background-color: #e0f7fa;
}
.addMovement-wrapper > .timePeriod-buttons-wrapper {
  width: calc(100% - 32px);
  display: flex;
}
.addMovement-wrapper > .timePeriod-buttons-wrapper > div {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 3px 8px;
  margin-right: 8px;
  cursor: pointer;
}
.addMovement-wrapper > .timePeriod-buttons-wrapper > .highlighted {
  background: #e0f7fa;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/MyAdds/components/ScoreBoardWithOffers/AddMovement/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACF;AAAE;EACE,wBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAEJ;AADI;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGN;AADI;EACE,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AAGN;AADI;EACE,yBAAA;AAGN;AAAE;EACE,wBAAA;EACA,aAAA;AAEJ;AADI;EACE,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAGN;AADI;EACE,mBAAA;AAGN","sourcesContent":[".addMovement-wrapper {\n  width: 100%;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-bottom: 16px;\n  border-radius: 4px;\n  > .top {\n    width: calc(100% - 32px);\n    margin-top: 16px;\n    height: 50px;\n    z-index: 500;\n    > h1 {\n      font-family: Lora;\n      font-size: 24px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 26px;\n      margin-bottom: 16px;\n    }\n    > p {\n      font-family: Source Sans Pro;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 18px;\n      padding: 3px 8px;\n      width: fit-content;\n      margin-bottom: 8px;\n      text-transform: uppercase;\n    }\n    > .highlighted {\n      background-color: #e0f7fa;\n    }\n  }\n  > .timePeriod-buttons-wrapper {\n    width: calc(100% - 32px);\n    display: flex;\n    > div {\n      font-family: Source Sans Pro;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 18px;\n      padding: 3px 8px;\n      margin-right: 8px;\n      cursor: pointer;\n    }\n    > .highlighted {\n      background: #e0f7fa;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
