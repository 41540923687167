import { useEffect, useState } from "react";
import "./ZohoMiniApp.css";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "../../config/axiosConfig";
import { getLocalStorageItem } from "../../config/localStorageEncryption";
import { Password } from "@mui/icons-material";

function ZohoMiniApp() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [clientConfig, setClientConfig] = useState({
    "Client ID": "",
    "Client Secret": "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async (e) => {
    e?.preventDefault();
    toast.info("Authenticating user .....", {
      id: "submitting-change",
      autoClose: false,
    });

    setIsSubmitting(true);
    await instance
      .post(`/zohoAuth/admin-login`, loginCredentials)
      .then((response) => {
        toast.dismiss();
        if (response?.data?.isAuthenticated) {
          toast.success("Authenticated !");
          setIsAuthenticated(true);
          setLoginCredentials({
            email:"",
            password:""
          })
        } else {
          toast.error("Wrong credentials !");
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(
          error?.response?.data["Message"] ||
            error?.response?.data?.errors?.join(" ")
        );
        setIsSubmitting(false);
      });
  };

  const handleChangeLoginCredentials = async (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    setLoginCredentials((prev) => {
      prev[`${name}`] = value;
      return prev;
    });
  };

  const handleChangeClientProperties = async (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    setClientConfig((prev) => {
      prev[`${name}`] = value;
      return prev;
    });
  };

  const handleSubmitClientInfo = async (e) => {
    e?.preventDefault();
    toast.info("Getting authorization url....", {
      id: "submitting-change",
      autoClose: false,
    });

    setIsSubmitting(true);
    await instance
      .post(`/zohoAuth/authorize`, clientConfig)
      .then((response) => {
        toast.dismiss();
        setIsSubmitting(false);
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(
          error?.response?.data["Message"] ||
            error?.response?.data?.errors?.join(" ")
        );
        setIsSubmitting(false);
      });
  };
  useEffect(() => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const urlParams = new URLSearchParams(queryString);

    // Get the values of the parameters
    const code = urlParams.get("code");
    const location = urlParams.get("location");
    const accountsServer = urlParams.get("accounts-server");
    let dataObject = {
      code,
      location,
      accountsServer,
    };
    const sendCodeToBackend = async () => {
      toast.info("Updating zoho token....", {
        id: "submitting-change",
        autoClose: false,
      });
      setIsSubmitting(true);
      await instance
        .post(`/zohoAuth/authorize/callback`, dataObject)
        .then((response) => {
          toast.dismiss();
          setIsSubmitting(false);
          toast.success(response.data);
        })
        .catch((error) => {
          toast.dismiss();
          toast.error(
            error?.response?.data["Message"] ||
              error?.response?.data?.errors?.join(" ")
          );
          setIsSubmitting(false);
        });
    };
    if (code && location && accountsServer) {
      sendCodeToBackend();
    }
  }, []);
  return (
    <>
      <ToastContainer style={{ zIndex: 9999 }} />
      {isAuthenticated ? (
        <section className="login-container">
          <div className="logo">
            <img src="https://static-prod-abidors.s3.uk.io.cloud.ovh.net/f4d3ec41-3c40-44ae-957b-181ea8b0da5d.avif" />
          </div>
          <div className="form-container">
            <div>
              <h1 className="form-title">
                provide client info( Make sure that the client exists from
                <a href="https://api-console.zoho.com/">zoho api console</a>)
              </h1>
              <form onSubmit={handleSubmitClientInfo} id="knakda">
                <div className="form-field">
                  <label htmlFor="Client ID">Client ID</label>
                  <input
                    type="text"
                    name="Client ID"
                    id="Client ID"
                    placeholder="..........."
                    required
                    onChange={handleChangeClientProperties}
                    autoComplete="off"
                    defaultValue={clientConfig["Client ID"]}
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="Client Secret">Client Secret</label>
                  <input
                    type="text"
                    name="Client Secret"
                    id="Client Secret"
                    placeholder="••••••••"
                    required
                    onChange={handleChangeClientProperties}
                    autoComplete="off"
                    defaultValue={clientConfig["Client Secret"]}
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="submit-button"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </section>
      ) : (
        <section className="login-container">
          <div className="logo">
            <img
              src="https://static-prod-abidors.s3.uk.io.cloud.ovh.net/65f1c409-3601-41b6-838a-8744fa7e526e.svg"
              alt="logo"
            />
          </div>
          <div className="form-container">
            <div>
              <h1 className="form-title">Sign in to have access</h1>
              <form onSubmit={handleLogin} id="a fmafla">
                <div className="form-field">
                  <label htmlFor="email">Your email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="name@company.com"
                    required
                    onChange={handleChangeLoginCredentials}
                    autoComplete="off"
                    defaultValue={loginCredentials.email}
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="password">Password</label>
                  <input
                    type="text"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    required
                    onChange={handleChangeLoginCredentials}
                    autoComplete="off"
                    defaultValue={loginCredentials.password}
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="submit-button"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ZohoMiniApp;
