// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }
.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/Lawyer/components/StepProgressBar/StepProgressBar.css"],"names":[],"mappings":"AAAA,+BAA+B,yBAAyB,EAAE;AAC1D,kCAAkC,yBAAyB,EAAE","sourcesContent":[".MuiStepIcon-root.Mui-active { color: #4CAF50 !important; }\n.MuiStepIcon-root.Mui-completed { color: #4CAF50 !important; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
