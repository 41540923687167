// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  top: 84px !important;
  padding: 20px 10px;
}

.notification-link {
  color: #000
}

.notification-link:hover {
  color: #000
}

.notification-link:visited {
  color: #000
}

.notification-row .left-part {
  float: left;
}

.notification-row .notification-title {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  margin: 0;
}

.notification-row .notification-content {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin: 0;
}


.notification-row .right-part {
  float: right;
  font-size: 12px;
}

.no-notifications {
  text-align: center;
  font-size: 14px;
  margin: 0;
  padding: 20px 0;
}
.loading-more-notifications{
  margin-top: 5px;
  margin-left: 10px;
  color: #0097A7;
}

@media screen and (max-width:1320px) {
  
  .header-lawyer .notification-icon {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .header-lawyer .notification-icon {
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media screen and (max-width:453px) {
  .notification-icon {
    margin-right: 10px;
  
  }
}


`, "",{"version":3,"sources":["webpack://./src/components/common/Notifications/notification.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;;AAGA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,SAAS;EACT,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;;EAEE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;AACA;EACE;IACE,kBAAkB;;EAEpB;AACF","sourcesContent":[".css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{\n  top: 84px !important;\n  padding: 20px 10px;\n}\n\n.notification-link {\n  color: #000\n}\n\n.notification-link:hover {\n  color: #000\n}\n\n.notification-link:visited {\n  color: #000\n}\n\n.notification-row .left-part {\n  float: left;\n}\n\n.notification-row .notification-title {\n  padding: 7px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 13px;\n  margin: 0;\n}\n\n.notification-row .notification-content {\n  padding: 7px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 12px;\n  margin: 0;\n}\n\n\n.notification-row .right-part {\n  float: right;\n  font-size: 12px;\n}\n\n.no-notifications {\n  text-align: center;\n  font-size: 14px;\n  margin: 0;\n  padding: 20px 0;\n}\n.loading-more-notifications{\n  margin-top: 5px;\n  margin-left: 10px;\n  color: #0097A7;\n}\n\n@media screen and (max-width:1320px) {\n  \n  .header-lawyer .notification-icon {\n    margin-right: 10px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .header-lawyer .notification-icon {\n    margin-top: 5px;\n    margin-left: 10px;\n  }\n}\n@media screen and (max-width:453px) {\n  .notification-icon {\n    margin-right: 10px;\n  \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
