// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photographer-tab{
  display: flex;
  flex-direction: column;
}
.photographer-tab .back-btn{
  background: #00BCD4;
  color:#fff;
  float: left;
  width: fit-content;
}

.photographer-tab .back-btn:hover{
  background: #00BCD4;
  color:#fff
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/Photographer/Photographer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB;AACF","sourcesContent":[".photographer-tab{\n  display: flex;\n  flex-direction: column;\n}\n.photographer-tab .back-btn{\n  background: #00BCD4;\n  color:#fff;\n  float: left;\n  width: fit-content;\n}\n\n.photographer-tab .back-btn:hover{\n  background: #00BCD4;\n  color:#fff\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
