// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#researchButton {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  box-sizing: border-box;
  background: #0066d9;
  color: #ffffff;
  cursor: pointer;
  width: 10%;
  height: 50px;
  align-items: center;
  justify-content: center;
}

#researchButton:hover,
#researchButton:active {
  background-color: #0358b8;
}
`, "",{"version":3,"sources":["webpack://./src/pages/VirtualTour/components/Viewer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":["#researchButton {\n  display: flex;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  border: none;\n  box-sizing: border-box;\n  background: #0066d9;\n  color: #ffffff;\n  cursor: pointer;\n  width: 10%;\n  height: 50px;\n  align-items: center;\n  justify-content: center;\n}\n\n#researchButton:hover,\n#researchButton:active {\n  background-color: #0358b8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
