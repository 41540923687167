// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.premium-property-slider-container{
	position: relative;
	max-width: 100%;
  transform: scale(0.9);
  margin-top: -50px;
}

.premium-slider .slick-slide > div {
  margin: 0 10px !important;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0 -10px;
  max-width: 100%;
}

.prev-btn,.next-btn{
    position: absolute;
    display: block;
    top: 50%;
    transform: translate(0, -60%);
    cursor: pointer;
    font-size: 40px;
    color:#00bcd4;
    z-index: 999 !important;
}

.premium_listings.swiper-slide {
  margin-right: 20px !important;
}

.premium_listings_new.swiper-slide {
  margin-right: 16px !important;
}

.prev-btn{
    left: 20px !important;
}

.next-btn{
    right: 0 !important;
}

.no-result-container{
  display:flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .premium-property-slider-container{
    transform: scale(1);
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/PremiumListings/style.css"],"names":[],"mappings":";AACA;CACC,kBAAkB;CAClB,eAAe;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,eAAe;AACjB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,QAAQ;IACR,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;AAC3B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;EACf;AACF","sourcesContent":["\n.premium-property-slider-container{\n\tposition: relative;\n\tmax-width: 100%;\n  transform: scale(0.9);\n  margin-top: -50px;\n}\n\n.premium-slider .slick-slide > div {\n  margin: 0 10px !important;\n}\n\n.slick-list {\n  position: relative;\n  display: block;\n  margin: 0 -10px;\n  max-width: 100%;\n}\n\n.prev-btn,.next-btn{\n    position: absolute;\n    display: block;\n    top: 50%;\n    transform: translate(0, -60%);\n    cursor: pointer;\n    font-size: 40px;\n    color:#00bcd4;\n    z-index: 999 !important;\n}\n\n.premium_listings.swiper-slide {\n  margin-right: 20px !important;\n}\n\n.premium_listings_new.swiper-slide {\n  margin-right: 16px !important;\n}\n\n.prev-btn{\n    left: 20px !important;\n}\n\n.next-btn{\n    right: 0 !important;\n}\n\n.no-result-container{\n  display:flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media screen and (max-width: 640px) {\n  .premium-property-slider-container{\n    transform: scale(1);\n    margin-top: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
