// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    position: relative;
    width: 100%;
    margin-bottom: 64px;
    padding: 20px 0;
}

.banner .title-banner {
    font-size: 72px;
    line-height: 76px;
    text-align: center;
    color: #000;
    font-family: 'Lora';
    font-weight: 500;
}

@media screen and (max-width:520px){
    .banner .title-banner {
        font-size: 40px;
        text-align: center;
        line-height: 1;
    }
}

@media screen and (max-width:320px){
    .banner {
        margin-bottom: 20px;
    }

    .banner .title-banner {
        font-size: 30px;
        line-height: 1;
        text-align: center;
    }
    .privacyPolicy .banner{
        padding:0 5px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Banner/Banner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;QACf,kBAAkB;QAClB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,cAAc;QACd,kBAAkB;IACtB;IACA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".banner {\n    position: relative;\n    width: 100%;\n    margin-bottom: 64px;\n    padding: 20px 0;\n}\n\n.banner .title-banner {\n    font-size: 72px;\n    line-height: 76px;\n    text-align: center;\n    color: #000;\n    font-family: 'Lora';\n    font-weight: 500;\n}\n\n@media screen and (max-width:520px){\n    .banner .title-banner {\n        font-size: 40px;\n        text-align: center;\n        line-height: 1;\n    }\n}\n\n@media screen and (max-width:320px){\n    .banner {\n        margin-bottom: 20px;\n    }\n\n    .banner .title-banner {\n        font-size: 30px;\n        line-height: 1;\n        text-align: center;\n    }\n    .privacyPolicy .banner{\n        padding:0 5px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
