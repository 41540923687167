// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacyPolicy {
    margin-bottom: 128px;
    font-size: 18px;
    line-height: 32px;
}

.privacyPolicy .banner {
    padding: 22px 60px;
}

.privacyPolicy-container {
    margin: 0 149px
}

.privacyPolicy .title {
    font-size: 25px;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: 10px;
}

.privacyPolicy .title-sub {
    margin-left: 20px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: 10px;
}

.privacyPolicy .title-sub-sub {
    margin-left: 30px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: 10px;
}

.privacyPolicy .ul {
    margin-left: 60px;
    list-style-type: none;
}

@media screen and (max-width:900px){
    .privacyPolicy-container {
       margin: 0 80px;
       text-indent: 0 !important;
       }
    
  }
  
  @media screen and (max-width:600px){
    .privacyPolicy-container { margin: 0 30px }
    .privacyPolicy .ul { margin-left: 0px;}
  
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/SecondaryPages/PrivacyPolicy/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI;OACG,cAAc;OACd,yBAAyB;OACzB;;EAEL;;EAEA;IACE,2BAA2B,eAAe;IAC1C,qBAAqB,gBAAgB,CAAC;;EAExC","sourcesContent":[".privacyPolicy {\n    margin-bottom: 128px;\n    font-size: 18px;\n    line-height: 32px;\n}\n\n.privacyPolicy .banner {\n    padding: 22px 60px;\n}\n\n.privacyPolicy-container {\n    margin: 0 149px\n}\n\n.privacyPolicy .title {\n    font-size: 25px;\n    font-family: Roboto;\n    font-weight: 700;\n    margin-bottom: 10px;\n}\n\n.privacyPolicy .title-sub {\n    margin-left: 20px;\n    font-size: 20px;\n    font-family: Roboto;\n    font-weight: 400;\n    margin-bottom: 10px;\n}\n\n.privacyPolicy .title-sub-sub {\n    margin-left: 30px;\n    font-size: 20px;\n    font-family: Roboto;\n    font-weight: 400;\n    margin-bottom: 10px;\n}\n\n.privacyPolicy .ul {\n    margin-left: 60px;\n    list-style-type: none;\n}\n\n@media screen and (max-width:900px){\n    .privacyPolicy-container {\n       margin: 0 80px;\n       text-indent: 0 !important;\n       }\n    \n  }\n  \n  @media screen and (max-width:600px){\n    .privacyPolicy-container { margin: 0 30px }\n    .privacyPolicy .ul { margin-left: 0px;}\n  \n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
