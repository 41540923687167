// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  top: 84px !important;
  padding: 20px 10px;
}


.notification-link{
  color:#000
}

.notification-link:hover{
  color:#000
}
.notification-link:visited{
  color:#000
}

.left-part {
  float: left;
}

.notification-title {
  font-size: 13px;
  margin: 0;
}

.notification-row .notification-content {
  font-size: 12px;
  margin: 0;
}

.notification-row .right-part {
  float: right;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/HeaderSeller/notification.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;;AAGA;EACE;AACF;;AAEA;EACE;AACF;AACA;EACE;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{\n  top: 84px !important;\n  padding: 20px 10px;\n}\n\n\n.notification-link{\n  color:#000\n}\n\n.notification-link:hover{\n  color:#000\n}\n.notification-link:visited{\n  color:#000\n}\n\n.left-part {\n  float: left;\n}\n\n.notification-title {\n  font-size: 13px;\n  margin: 0;\n}\n\n.notification-row .notification-content {\n  font-size: 12px;\n  margin: 0;\n}\n\n.notification-row .right-part {\n  float: right;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
