// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-logout-button {
    float: left;
    padding: 15px 32px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: #000;
    border-radius: 4px;
    border: 2px solid #00BCD4;
    cursor: pointer;
    text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/socialLogoutButton/socialLogoutButton.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".social-logout-button {\n    float: left;\n    padding: 15px 32px;\n    margin-right: 20px;\n    font-size: 14px;\n    line-height: 18px;\n    font-family: 'Source Sans Pro';\n    font-weight: 700;\n    color: #000;\n    border-radius: 4px;\n    border: 2px solid #00BCD4;\n    cursor: pointer;\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
