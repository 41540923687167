// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-virtual-assistant-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
  padding-left: 32px;
  width: calc(100% - 64px);
}

@media screen and (max-width:400px){

  .seller-virtual-assistant-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  0px 32px;
    width:100%;
  }
}


`, "",{"version":3,"sources":["webpack://./src/pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/VirtualAssistantProfile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;;EAEE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;EACZ;AACF","sourcesContent":[".seller-virtual-assistant-profile {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 32px 0px;\n  padding-left: 32px;\n  width: calc(100% - 64px);\n}\n\n@media screen and (max-width:400px){\n\n  .seller-virtual-assistant-profile {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding:  0px 32px;\n    width:100%;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
