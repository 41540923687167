// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-successful { position: relative; text-align:center; margin: 140px 190px 128px 190px; }
.registration-successful .title { font-size: 48px; margin-bottom: 10px; }

.registration-successful .text { font-size: 16px; margin-bottom: 40px; }

.registration-successful .icon { margin-bottom: 35px; }

.registration-successful .button { padding: 12px 32px; background-color: #000; border-radius: 12px; color: #E2E2E2; text-transform: none; }
.registration-successful .button:hover { background-color: #000; }`, "",{"version":3,"sources":["webpack://./src/pages/Photographer/Registration/RegistrationSuccess/RegistrationSuccess.css"],"names":[],"mappings":"AAAA,2BAA2B,kBAAkB,EAAE,iBAAiB,EAAE,+BAA+B,EAAE;AACnG,kCAAkC,eAAe,EAAE,mBAAmB,EAAE;;AAExE,iCAAiC,eAAe,EAAE,mBAAmB,EAAE;;AAEvE,iCAAiC,mBAAmB,EAAE;;AAEtD,mCAAmC,kBAAkB,EAAE,sBAAsB,EAAE,mBAAmB,EAAE,cAAc,EAAE,oBAAoB,EAAE;AAC1I,yCAAyC,sBAAsB,EAAE","sourcesContent":[".registration-successful { position: relative; text-align:center; margin: 140px 190px 128px 190px; }\n.registration-successful .title { font-size: 48px; margin-bottom: 10px; }\n\n.registration-successful .text { font-size: 16px; margin-bottom: 40px; }\n\n.registration-successful .icon { margin-bottom: 35px; }\n\n.registration-successful .button { padding: 12px 32px; background-color: #000; border-radius: 12px; color: #E2E2E2; text-transform: none; }\n.registration-successful .button:hover { background-color: #000; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
