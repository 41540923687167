// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dialog-property-image-view-modal-content{
    padding: 2.5%;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.dialog-property-image-view-modal-content .close-icon {
    display: flex;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
}

.dialog-property-image-view-modal-content .previous-image-icon{
    display: flex;
    height: 100px;
    width: 50px;
    background-color: black;
    border-radius: 6px;
    padding: 0 !important;
    padding-left: 2px;
    position: absolute;
    left:20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dialog-property-image-view-modal-content .next-image-icon{
    display: flex;
    height: 100px;
    width: 50px;
    background-color: black;
    border-radius: 6px;
    padding: 0 !important;
    padding-left: 2px;
    position: absolute;
    right:20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/components/common/PropertyViewModal/PropertyImagViewModal/PropertyImageViewModal.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,SAAS;IACT,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;;AAEnB","sourcesContent":["\n.dialog-property-image-view-modal-content{\n    padding: 2.5%;\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n}\n\n.dialog-property-image-view-modal-content .close-icon {\n    display: flex;\n    position: absolute;\n    cursor: pointer;\n    right: 10px;\n    top: 10px;\n    height: 40px;\n    width: 40px;\n    justify-content: center;\n    align-items: center;\n}\n\n.dialog-property-image-view-modal-content .previous-image-icon{\n    display: flex;\n    height: 100px;\n    width: 50px;\n    background-color: black;\n    border-radius: 6px;\n    padding: 0 !important;\n    padding-left: 2px;\n    position: absolute;\n    left:20px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.dialog-property-image-view-modal-content .next-image-icon{\n    display: flex;\n    height: 100px;\n    width: 50px;\n    background-color: black;\n    border-radius: 6px;\n    padding: 0 !important;\n    padding-left: 2px;\n    position: absolute;\n    right:20px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
