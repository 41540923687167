import './App.css'
import './index.css'
import './components/Footer/Footer.css'
import './components/common/PlaceAutoComplete/userLocation.css'
import './components/common/Contract/Contract.css'
import './components/common/propertySlider/propertySlides.css'
import './components/common/Accents/Accent.css'
import './components/common/PremiumListings/style.css'
import './components/common/CustomerAnalysis/CustomerAnalysis.css'
import './components/common/UnsubscribeEmailsModal/EmailsSubscription.css'
import './components/common/ReviewSection/ReviewSection.css'
import './components/common/ReviewSection/ReviewItem/ReviewItem.css'
import './components/common/Property/Property.css'
import './components/common/Multiprofile/Multiprofile.css'
import './components/common/socialLoginButton/socialLogin.css'
import './components/common/StepProgressBar/StepProgressBar.css'
import './components/common/socialLogoutButton/socialLogoutButton.css'
import './components/common/AddToHomeScreenBanner/AddToHomeScreenBanner.css'
import './components/common/Button/ButtonFilled.css'
import './components/common/Button/ButtonOutline.css'
import './components/common/Button/Button.css'
import './components/common/LangSwitcher/LangSwitcher.css'
import './components/common/ChatBox/ChatBox.css'
import './components/common/ChatBox/ChatBlob/ChatBlob.css'
import './components/common/ChatBox/NotificationBlob/NotificationBlob.css'
import './components/common/SearchWords/SearchWords.css'
import './components/common/PropertyCard/PropertyCard.css'
import './components/common/Notifications/notification.css'
import './components/common/Banner/Banner.css'
import './components/Header/HeaderBuyer/HeaderBuyer.css'
import './components/Header/HeaderLawyer/HeaderLawyer.css'
import './components/Header/HeaderPhotographer/HeaderPhotographer.css'
import './components/Header/HeaderVirtualAssistant/HeaderVirtualAssistant.css'
import './components/Header/HeaderWithoutSignIn/Header.css'
import './components/Header/HeaderSeller/notification.css'
import './components/Header/HeaderSeller/HeaderSeller.css'
import './components/LazyWrapper/LazyWrapper.css'
import './pages/Seller/VisualsPage/components/PhotoUploadModal/styles.css'
import './pages/Seller/VisualsPage/components/VideoUploadModal/styles.css'
import './pages/Seller/VisualsPage/components/ThreeSixtyViewUploadModal/styles.css'
import './pages/Seller/VisualsPage/VisualPage.css'
import './pages/Seller/HomePage/Homepage.css'
import './pages/Seller/InformationPage/components/LocationDetails/LocationDetails.css'
import './pages/Seller/InformationPage/components/PropertyDetails/PropertyDetails.css'
import './pages/Seller/InformationPage/InformationPage.css'
import './pages/Seller/MyProfile/VirtualAssistant/Video/Video.css'
import './pages/Seller/MyProfile/VirtualAssistant/VirtualMeetingRequest/VirtualMeetingRequest.css'
import './pages/Seller/MyProfile/VirtualAssistant/AbortRelationship/AbortRelationship.css'
import './pages/Seller/MyProfile/VirtualAssistant/VirtualAssistant.css'
import './pages/Seller/MyProfile/VirtualAssistant/RelationshipSuggestion/RelationshipSuggestion.css'
import './pages/Seller/MyProfile/VirtualAssistant/Questionnaire/Questionnaire.css'
import './pages/Seller/MyProfile/VirtualAssistant/Relationship/Relationship.css'
import './pages/Seller/MyProfile/VirtualAssistant/CancelVirtualMeeting/CancelVirtualMeeting.css'
import './pages/Seller/MyProfile/VirtualAssistant/JoinMeeting/JoinMeeting.css'
import './pages/Seller/MyProfile/VirtualAssistant/NoVirtualAgent/NoVirtualAgent.css'
import './pages/Seller/MyProfile/Lawyer/VirtualMeetingRequest/VirtualMeetingRequest.css'
import './pages/Seller/MyProfile/Lawyer/LawyerContract/LawyerContract.css'
import './pages/Seller/MyProfile/Lawyer/RelationshipSuggestion/RelationshipSuggestion.css'
import './pages/Seller/MyProfile/Lawyer/Relationship/Video/Survey/Survey.css'
import './pages/Seller/MyProfile/Lawyer/Relationship/Relationship.css'
import './pages/Seller/MyProfile/Lawyer/Lawyer.css'
import './pages/Seller/MyAdds/styles.css'
import './pages/Seller/MyAdds/tabstyles.css'
import './pages/Seller/MyAdds/components/ListingCard/styles.css'
import './pages/Seller/MyAdds/components/Offers/Offers.css'
import './pages/Seller/MyAdds/components/LocationDetails/LocationDetails.css'
import './pages/Seller/MyAdds/components/VIPad/non-premium/styles.css'
import './pages/Seller/MyAdds/components/VIPad/premium/style.css'
import './pages/Seller/MyAdds/components/propertyDetails/PropertyDetails.css'
import './pages/Seller/Questionnaire/Questionnaire.css'
import './pages/Seller/Dashboard/Dashboard.css'
import './pages/Seller/Dashboard/Step/PreliminaryContract/PreliminaryContract.css'
import './pages/Seller/Dashboard/Step/Answer/Answer.css'
import './pages/Seller/Dashboard/Step/StopDeposit/StopDeposit.css'
import './pages/Seller/Dashboard/Step/NoInspecionYet/NoInspecionYet.css'
import './pages/Seller/Dashboard/Step/PreparationBeforeView/PreparationBeforeView.css'
import './pages/Seller/components/ButtonUnFilled/styles.css'
import './pages/Seller/components/Button/Button.css'
import './pages/Seller/components/CreateAnAddFlowStepIndicator/CreateAnAddFlow.css'
import './pages/Seller/SellerProfile/components/Contract/Contract.css'
import './pages/Seller/SellerProfile/components/Contract/ContractBody/ContractBody.css'
import './pages/Seller/SellerProfile/components/CurrentАccount/CurrentАccount.css'
import './pages/Seller/SellerProfile/components/PaymentMethod/PaymentMethod.css'
import './pages/Seller/SellerProfile/components/PaymentMethod/WithMethod/WithMethod.css'
import './pages/Seller/SellerProfile/components/PaymentMethod/WithoutMethod/WithoutMethod.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/ServiceCompletion/ServiceCompletion.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/StartingService/StartingService.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/PaymentForService/PaymentForService.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/ActiveServiceRequest.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/ServiceConfirmation/ServiceConfirmation.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/MakingContact/MakingContact.css'
import './pages/Seller/SellerProfile/components/ActiveServiceRequest/NegotiatingClientNeeds/NegotiatingClientNeeds.css'
import './pages/Seller/SellerProfile/components/PendingInquiries/PendingInquiries.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/VirtualMeetingRequest/VirtualMeetingRequest.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/AbortRelationship/AbortRelationship.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/VideoConversation/VideoConversation.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/VirtualAssistant.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/AwaitingApproval/AwaitingApproval.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/Questionnaire/Questionnaire.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/Relationship/Relationship.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/CancelVirtualMeeting/CancelVirtualMeeting.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/JoinMeeting/JoinMeeting.css'
import './pages/Seller/SellerProfile/components/VirtualAssistant/NoVirtualAgent/NoVirtualAgent.css'
import './pages/Seller/SellerProfile/components/Photographer/Photographer.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/PaymentForService/PaymentForService.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/HeaderContent/AppointmentSet/AppointmentSet.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/HeaderContent/HeaderContent.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/StepProgressBar/StepProgressBar.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/ServicesOffered/ServicesOffered.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/ServicesOffered/ServiceItem/ServiceItem.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/RequestMeeting/RequestMeeting.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/PhotoUploadPane/PhotoUploadPane.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerServices/PhotographerServices.css'
import './pages/Seller/SellerProfile/components/Photographer/NoPhotographer/NoPhotographer.css'
import './pages/Seller/SellerProfile/components/Photographer/PhotographerSuggestion/PhotographerSuggestion.css'
import './pages/Seller/SellerProfile/components/Lawyer/VirtualMeetingRequest/VirtualMeetingRequest.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerContract/LawyerContract.css'
import './pages/Seller/SellerProfile/components/Lawyer/RelationshipSuggestion/RelationshipSuggestion.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/StartingService/StartingService.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/PaymentForService/PaymentForService.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/VideoConversation/VideoConversation.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/StepProgressBar/StepProgressBar.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/PropertyBox/PropertyBox.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/ServiceConfirmation/ServiceConfirmation.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/MakingContact/MakingContact.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/SessionSetup/SessionSetup.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/LawyerServices.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/JoinMeeting/JoinMeeting.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/RequestMeeting/RequestMeeting.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/DocumentsPane/DocumentsPane.css'
import './pages/Seller/SellerProfile/components/Lawyer/LawyerServices/NegotiatingClientNeeds/NegotiatingClientNeeds.css'
import './pages/Seller/SellerProfile/components/Lawyer/Relationship/Video/Survey/Survey.css'
import './pages/Seller/SellerProfile/components/Lawyer/Relationship/Relationship.css'
import './pages/Seller/SellerProfile/components/Lawyer/Lawyer.css'
import './pages/Seller/SellerProfile/SellerProfile.css'
import './pages/Seller/PricePage/styles.css'
import './pages/Seller/PublicProfile/components/Reviews/ReviewCard/ReviewCard.css'
import './pages/Seller/PublicProfile/components/Reviews/Reviews.css'
import './pages/Seller/PublicProfile/Profile.css'
import './pages/Seller/DocumentsPage/styles.css'
import './pages/Seller/DocumentsPage/components/CompletePostModal/CompletePostModal.css'
import './pages/ConfirmNewPassword/ConfirmNewPassword.css'
import './pages/ForgotPassword/ForgotPassword.css'
import './pages/VirtualTour/index.css'
import './pages/VirtualTour/components/sidebarMenus/sidebarMenus.css'
import './pages/VirtualTour/components/Viewer.css'
import './pages/SecondaryPages/Contact/Contact.css'
import './pages/SecondaryPages/HowToBuyProperty/HowToBuyProperty.css'
import './pages/SecondaryPages/About/About.css'
import './pages/SecondaryPages/HowToSellProperty/HowToSellProperty.css'
import './pages/SecondaryPages/Blog/Blog.css'
import './pages/SecondaryPages/GeneralTerms/GeneralTerms.css'
import './pages/SecondaryPages/FrequentlyQuastion/FrequentlyQuastion.css'
import './pages/SecondaryPages/PrivacyPolicy/PrivacyPolicy.css'
import './pages/SecondaryPages/Pricing/PromotionPlans/PromotionPlans.css'
import './pages/SecondaryPages/Pricing/Pricing.css'
import './pages/VirtualAssistant/Home/Home.css'
import './pages/VirtualAssistant/MyProfile/Contract/Contract.css'
import './pages/VirtualAssistant/MyProfile/MyProfile.css'
import './pages/VirtualAssistant/Education/Education.css'
import './pages/VirtualAssistant/PublicProfileEdit/PublicProfileEdit.css'
import './pages/VirtualAssistant/VipProfile/Contract/Contract.css'
import './pages/VirtualAssistant/VipProfile/PaymentMethod/PaymentMethod.css'
import './pages/VirtualAssistant/VipProfile/PaymentMethod/WithMethod/WithMethod.css'
import './pages/VirtualAssistant/VipProfile/PaymentMethod/WithoutMethod/WithoutMethod.css'
import './pages/VirtualAssistant/VipProfile/VipProfile.css'
import './pages/VirtualAssistant/VipProfile/VipProfileBid/VipProfileBid.css'
import './pages/VirtualAssistant/VipProfile/VipProfileBid/VipProfileBidDialog/VipProfileBidDialog.css'
import './pages/VirtualAssistant/VipProfile/VipPlace/HideVipPlace/HideVipPlace.css'
import './pages/VirtualAssistant/VipProfile/VipPlace/VipPlace.css'
import './pages/VirtualAssistant/VipProfile/VipPlace/ShowVipPlace/ShowVipPlace.css'
import './pages/VirtualAssistant/Dashboard/BuyerTab/BuyerTab.css'
import './pages/VirtualAssistant/Dashboard/EarningTab/EarningTab.css'
import './pages/VirtualAssistant/Dashboard/EarningTab/AddPaymentMethod/AddPaymentMethod.css'
import './pages/VirtualAssistant/Dashboard/Dashboard.css'
import './pages/VirtualAssistant/Dashboard/SellerTab/SellerTab.css'
import './pages/VirtualAssistant/Dashboard/InvitationTab/InvitationTab.css'
import './pages/VirtualAssistant/Dashboard/ReferralTab/ReferralTab.css'
import './pages/VirtualAssistant/Components/SellerAccountBox/SellerAccountBox.css'
import './pages/VirtualAssistant/Components/ClientRelationship/ClientRelationship.css'
import './pages/VirtualAssistant/Components/OfferBox/OfferBox.css'
import './pages/VirtualAssistant/Components/PropertyBox/PropertyBox.css'
import './pages/VirtualAssistant/Components/ClientRequestBox/ClientRequestBox.css'
import './pages/VirtualAssistant/Components/Button/ButtonFilled.css'
import './pages/VirtualAssistant/Components/Button/ButtonOutline.css'
import './pages/VirtualAssistant/Components/Profile/Profile.css'
import './pages/VirtualAssistant/Components/History/History.css'
import './pages/VirtualAssistant/Components/BuyerAccountBox/BuyerAccountBox.css'
import './pages/VirtualAssistant/ExamTest/ExamTest.css'
import './pages/VirtualAssistant/ExamTest/WrongTest/WrongTest.css'
import './pages/VirtualAssistant/ExamTest/SuccessTest/SuccessTest.css'
import './pages/VirtualAssistant/Exam/Exam.css'
import './pages/VirtualAssistant/PublicProfile/PublicProfile.css'
import './pages/Photographer/Home/Home.css'
import './pages/Photographer/Registration/Registration.css'
import './pages/Photographer/Registration/RegistrationSuccess/RegistrationSuccess.css'
import './pages/Photographer/MultiProfile/MultiProfile.css'
import './pages/Photographer/MyProfile/MyProfile.css'
import './pages/Photographer/VipProfile/Contract/Contract.css'
import './pages/Photographer/VipProfile/PaymentMethod/PaymentMethod.css'
import './pages/Photographer/VipProfile/PaymentMethod/WithMethod/WithMethod.css'
import './pages/Photographer/VipProfile/PaymentMethod/WithoutMethod/WithoutMethod.css'
import './pages/Photographer/VipProfile/VipProfile.css'
import './pages/Photographer/VipProfile/VipProfileBid/VipProfileBid.css'
import './pages/Photographer/VipProfile/VipProfileBid/VipProfileBidDialog/VipProfileBidDialog.css'
import './pages/Photographer/VipProfile/VipPlace/HideVipPlace/HideVipPlace.css'
import './pages/Photographer/VipProfile/VipPlace/VipPlace.css'
import './pages/Photographer/VipProfile/VipPlace/ShowVipPlace/ShowVipPlace.css'
import './pages/Photographer/Dashboard/Clients/NoServicesYet/NoServicesYet.css'
import './pages/Photographer/Dashboard/Clients/ClientAccountBox/ClientAccountBox.css'
import './pages/Photographer/Dashboard/Clients/ClientRequests/ClientRequests.css'
import './pages/Photographer/Dashboard/Dashboard.css'
import './pages/Photographer/Dashboard/Services/Services.css'
import './pages/Photographer/Dashboard/Services/ServiceCompletion/ServiceCompletion.css'
import './pages/Photographer/Dashboard/Services/StartingService/StartingService.css'
import './pages/Photographer/Dashboard/Services/PaymentForService/PaymentForService.css'
import './pages/Photographer/Dashboard/Services/PropertyBox/PropertyBox.css'
import './pages/Photographer/Dashboard/Services/MakingContact/MakingContact.css'
import './pages/Photographer/Dashboard/Services/SessionSetup/SessionSetup.css'
import './pages/Photographer/Dashboard/Services/WaitingConfirmation/WaitingConfirmation.css'
import './pages/Photographer/Dashboard/Services/ConfirmCompletion/ConfirmCompletion.css'
import './pages/Photographer/Dashboard/Services/NegotiatingNeeds/NegotiatingNeeds.css'
import './pages/Photographer/Dashboard/Services/ClientAccountBox/ClientAccountBox.css'
import './pages/Photographer/Dashboard/Invitations/Invitations.css'
import './pages/Photographer/Components/StepProgressBar/StepProgressBar.css'
import './pages/Photographer/Components/ClientRequestBox/ClientRequestBox.css'
import './pages/Photographer/Components/Button/ButtonFilled.css'
import './pages/Photographer/Components/Button/ButtonOutline.css'
import './pages/Photographer/Components/Profile/Profile.css'
import './pages/Photographer/PublicProfilePhotographer/PublicProfilePhotographer.css'
import './pages/Photographer/PublicProfilePhotographerEdit/PublicProfilePhotographerEdit.css'
import './pages/Lawyer/Home/HomeLawyer.css'
import './pages/Lawyer/PublicProfileLawyerEdit/PublicProfileLawyerEdit.css'
import './pages/Lawyer/MultiProfile/MultiProfile.css'
import './pages/Lawyer/MyProfile/MyProfile.css'
import './pages/Lawyer/MyProfile/components/Profile/Profile.css'
import './pages/Lawyer/MyProfile/components/History/History.css'
import './pages/Lawyer/PublicProfileLawyer/PublicProfileLawyer.css'
import './pages/Lawyer/VipProfile/Contract/Contract.css'
import './pages/Lawyer/VipProfile/PaymentMethod/PaymentMethod.css'
import './pages/Lawyer/VipProfile/VipProfile.css'
import './pages/Lawyer/VipProfile/VipProfileBid/VipProfileBid.css'
import './pages/Lawyer/VipProfile/VipProfileBid/VipProfileBidDialog/VipProfileBidDialog.css'
import './pages/Lawyer/VipProfile/VipPlace/HideVipPlace/HideVipPlace.css'
import './pages/Lawyer/VipProfile/VipPlace/VipPlace.css'
import './pages/Lawyer/VipProfile/VipPlace/ShowVipPlace/ShowVipPlace.css'
import './pages/Lawyer/Dashboard/Dashboard.css'
import './pages/Lawyer/Dashboard/Buyers/Buyers.css'
import './pages/Lawyer/Dashboard/Incomes/Incomes.css'
import './pages/Lawyer/Dashboard/Sellers/Sellers.css'
import './pages/Lawyer/Dashboard/Invitations/Invitations.css'
import './pages/Lawyer/components/SellerAccountBox/SellerAccountBox.css'
import './pages/Lawyer/components/ServiceCompletion/ServiceCompletion.css'
import './pages/Lawyer/components/PaymentWithMethod/PaymentWithMethod.css'
import './pages/Lawyer/components/PaymentForService/PaymentForService.css'
import './pages/Lawyer/components/StepProgressBar/StepProgressBar.css'
import './pages/Lawyer/components/DocumentFileBox/DocumentFileBox.css'
import './pages/Lawyer/components/PropertyBox/PropertyBox.css'
import './pages/Lawyer/components/ClientRequestBox/ClientRequestBox.css'
import './pages/Lawyer/components/Button/ButtonFilled.css'
import './pages/Lawyer/components/Button/ButtonOutline.css'
import './pages/Lawyer/components/Button/Button.css'
import './pages/Lawyer/components/Profile/Profile.css'
import './pages/Lawyer/components/PaymentWithoutMethod/PaymentWithoutMethod.css'
import './pages/Lawyer/components/History/History.css'
import './pages/Lawyer/components/WaitingConfirmation/WaitingConfirmation.css'
import './pages/Lawyer/components/BuyerAccountBox/BuyerAccountBox.css'
import './pages/Lawyer/components/ChatBox/ChatBox.css'
import './pages/Lawyer/components/ConfirmCompletion/ConfirmCompletion.css'
import './pages/Lawyer/components/NegotiatingNeeds/NegotiatingNeeds.css'
import './pages/Lawyer/components/DocumentBox/DocumentBox.css'
import './pages/Buyer/ChooseBank/ChoosenBank.css'
import './pages/Buyer/Home/Home.css'
import './pages/Buyer/MyProfile/LoanStatus/LoanStatus.css'
import './pages/Buyer/MyProfile/MyProfile.css'
import './pages/Buyer/MyProfile/MyOffers/ChangeOffer/ChangeOffer.css'
import './pages/Buyer/MyProfile/MyOffers/SendNewOffer/SendNewOffer.css'
import './pages/Buyer/MyProfile/MyOffers/AcceptOffer/AcceptOffer.css'
import './pages/Buyer/MyProfile/MyOffers/MyOffers.css'
import './pages/Buyer/MyProfile/MyOffers/RejectOffer/RejectOffer.css'
import './pages/Buyer/MyProfile/MyOffers/DeleteOffer/DeleteOffer.css'
import './pages/Buyer/MyProfile/Favorites/Favorite.css'
import './pages/Buyer/MyProfile/PendingInquiries/PendingInquiries.css'
import './pages/Buyer/MyProfile/VirtualAssistant/VirtualAssistant.css'
import './pages/Buyer/MyProfile/VirtualAssistant/AwaitingApproval/AwaitingApproval.css'
import './pages/Buyer/MyProfile/VirtualAssistant/Questionnaire/Questionnaire.css'
import './pages/Buyer/MyProfile/VirtualAssistant/Relationship/Relationship.css'
import './pages/Buyer/MyProfile/VirtualAssistant/NoVirtualAgent/NoVirtualAgent.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/ServiceCompletion/ServiceCompletion.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/StartingService/StartingService.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/PaymentForService/PaymentForService.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/ActiveServiceRequest.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/ServiceConfirmation/ServiceConfirmation.css'
import './pages/Buyer/MyProfile/Lawyer/ActiveServiceRequest/NegotiatingClientNeeds/NegotiatingClientNeeds.css'
import './pages/Buyer/MyProfile/Lawyer/Lawyer.css'
import './pages/Buyer/Questionnaire/Questionnaire.css'
import './pages/Buyer/Dashboard/Dashboard.css'
import './pages/Buyer/Dashboard/Step/PreliminaryContract/PreliminaryContract.css'
import './pages/Buyer/Dashboard/Step/Answer/Answer.css'
import './pages/Buyer/Dashboard/Step/StopDeposit/StopDeposit.css'
import './pages/Buyer/Dashboard/Step/NoInspecionYet/NoInspecionYet.css'
import './pages/Buyer/Dashboard/Step/PreparationBeforeView/PreparationBeforeView.css'
import './pages/Buyer/PublicProfile/components/Reviews/ReviewCard/ReviewCard.css'
import './pages/Buyer/PublicProfile/components/Reviews/Reviews.css'
import './pages/Buyer/PublicProfile/PublicProfile.css'
import './pages/WithoutLogin/EmailVerficationSuccessfull/SuccessfullVerfication.css'
import './pages/WithoutLogin/Home/Home.css'
import './pages/WithoutLogin/Registration/RegistrationSuccessful/RegistrationSuccessful.css'
import './pages/WithoutLogin/Registration/Registration.css'
import './pages/WithoutLogin/Registration/RegistrationAbout/RegistrationAbout.css'
import './pages/WithoutLogin/SearchPages/SearchPropertyDetails/SearchPropertyDetails.css'
import './pages/WithoutLogin/SearchPages/SearchPropertyDetails/mobileOffers.css'
import './pages/WithoutLogin/SearchPages/SearchProperties/mobileFilter.css'
import './pages/WithoutLogin/SearchPages/SearchProperties/SearchProperty.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/VirtualAssistantProfile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/VirtualAssistantSuccessDialog/VirtualAssistantSuccessDialog.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/Profile/Profile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/History/History.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/AskVirtualAssistantDialog/AskVirtualAssistantDialog.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/PhotographerProfile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/components/Profile/Profile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/components/Portfolio/Portfolio.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/components/RequestServiceDialog/RequestServiceDialog.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/LawyerProfile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/components/SendInquiryDialog/SendInquiryDialog.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/components/Profile/Profile.css'
import './pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/components/History/History.css'
import './pages/WithoutLogin/CommonThirdParty/components/FeaturedUser/FeaturedUser.css'
import './pages/WithoutLogin/CommonThirdParty/components/UserGridCard/UserGridCard.css'
import './pages/WithoutLogin/CommonThirdParty/components/UserListCard/UserListCard.css'
import './pages/WithoutLogin/CommonThirdParty/components/SearchSection/mobileFilter.css'
import './pages/WithoutLogin/CommonThirdParty/components/SearchSection/SearchSection.css'
import './pages/WithoutLogin/CommonThirdParty/components/ResultHeader/ResultHeader.css'
import './pages/WithoutLogin/CommonThirdParty/CommonThirdParty.css'
import './pages/WithoutLogin/Login/Login.css'

import "./pages/Seller/MyAdds/components/Information/Information.scss"
import "./pages/Seller/MyAdds/components/VIPad/Dialogs/paymentSuccess/styles.scss"
import "./pages/Seller/MyAdds/components/VIPad/Dialogs/savePayment/styles.scss"
import "./pages/Seller/MyAdds/components/Vision/styles.scss"
import "./pages/Seller/MyAdds/components/VideoUploader/styles.scss"
import "./pages/Seller/MyAdds/components/ScoreBoardWithoutOffers/styles.scss"
import "./pages/Seller/MyAdds/components/3DVideoUploader/styles.scss"
import "./pages/Seller/MyAdds/components/ScoreBoardWithOffers/AddMovement/styles.scss"
import "./pages/Seller/MyAdds/components/ScoreBoardWithOffers/styles.scss"
import "./pages/Seller/MyAdds/components/Documents/styles.scss"
import "./pages/Seller/MyAdds/components/Documents/components/DocCard/styles.scss"
import "./pages/Seller/MyAdds/components/Pricing/styles.scss"
import "./pages/Seller/MyAdds/MyAdds.css"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/CheckUploadedImagesModal/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/BuyerCheckAccept/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/AbortRelationship/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/UploadedMediaGallary/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/AbortRelationshipQuestionery/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/BuyerCheckDecline/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/SelectAdToAddUploads/styles.scss"
import "./pages/Seller/SellerProfile/components/Photographer/PhotographerServices/SelectAdToAddUploads/SmallAd/styles.scss"
import "./pages/Photographer/Components/Features/styles.scss"
import "./pages/Photographer/PublicProfilePhotographerEdit/components/PortfolioVideos.css"
import "./pages/Lawyer/MyProfile/components/LawyerServices/ServiceItem/ServiceItem.scss"
import "./pages/Lawyer/MyProfile/components/LawyerServices/LawyerServices.scss"
import "./pages/Lawyer/components/LawyerServices/ServiceItem/ServiceItem.scss"
import "./pages/Lawyer/components/LawyerServices/LawyerServices.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/Features/FeatureItem/FeatureItem.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/components/Features/Features.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/components/Features/styles.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/components/History/styles.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/components/LawyerServices/ServiceItem/ServiceItem.scss"
import "./pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/components/LawyerServices/LawyerServices.scss"
import "react-toastify/dist/ReactToastify.css";
// Libraries
import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "react-gallery-carousel/dist/index.css";
import "react-image-lightbox/style.css";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import "react-gallery-carousel/dist/index.css";
import "react-image-lightbox/style.css";
import "react-image-lightbox/style.css";


export {}