// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-filled {
    cursor: pointer;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button/ButtonFilled.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,WAAW;AACf","sourcesContent":[".button-filled {\n    cursor: pointer;\n    padding: 15px 32px;\n    background: #00BCD4;\n    border-radius: 4px;\n    font-family: 'Source Sans Pro';\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 18px;\n    text-transform: uppercase;\n    color: #FFFFFF;\n    border: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
