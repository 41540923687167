// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.place_autoCompletion_container{
    width:230px;
}

.place_autoCompletion_container .autoCompletion-input-box{
    background-color: #ededed; 
    padding: 20px;
    font-size: 16px;
    color: #000;
    width: 100%;
    border: none;
    border-radius: 8px;
}

.place_autoCompletion_container .autocomplete-dropdown-container{
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     width: 230px;
     
}

.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    z-index: 9999; 
  }`, "",{"version":3,"sources":["webpack://./src/components/common/PlaceAutoComplete/userLocation.css"],"names":[],"mappings":";AACA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;KACK,4CAA4C;KAC5C,YAAY;;AAEjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;EACf","sourcesContent":["\n.place_autoCompletion_container{\n    width:230px;\n}\n\n.place_autoCompletion_container .autoCompletion-input-box{\n    background-color: #ededed; \n    padding: 20px;\n    font-size: 16px;\n    color: #000;\n    width: 100%;\n    border: none;\n    border-radius: 8px;\n}\n\n.place_autoCompletion_container .autocomplete-dropdown-container{\n     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n     width: 230px;\n     \n}\n\n.autocomplete-dropdown {\n    position: absolute;\n    width: 100%;\n    z-index: 9999; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
