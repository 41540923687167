// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-blob { text-align: left; padding: 24px 16px 16px 16px; background: #F2FEFF; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }
.notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }
.notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.notification-blob .notification-blob-header .title { font-size: 14px; line-height: 18px; margin-left: 10px; font-family: 'Source Sans Pro'; font-weight: 700; text-transform: uppercase; }
.notification-blob .notification-blob-header .hour { font-size: 12px; line-height: 12px; font-family: 'Source Sans Pro'; font-weight: 400; margin-left: 5px; }
.notification-blob .text { font-size: 12px; line-height: 16px; letter-spacing: 0.4px; font-family: 'Source Sans Pro'; font-weight: 400; }`, "",{"version":3,"sources":["webpack://./src/components/common/ChatBox/NotificationBlob/NotificationBlob.css"],"names":[],"mappings":"AAAA,qBAAqB,gBAAgB,EAAE,4BAA4B,EAAE,mBAAmB,EAAE,iCAAiC,EAAE,kBAAkB,EAAE,kBAAkB,EAAE;AACrK,+CAA+C,aAAa,EAAE,mBAAmB,EAAE,kBAAkB,EAAE;AACvG,qDAAqD,kBAAkB,EAAE,MAAM,EAAE,gBAAgB,EAAE,gBAAgB,EAAE;AACrH,uDAAuD,WAAW,EAAE,YAAY,EAAE,kBAAkB,EAAE;AACtG,sDAAsD,eAAe,EAAE,iBAAiB,EAAE,iBAAiB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE,yBAAyB,EAAE;AAC1L,qDAAqD,eAAe,EAAE,iBAAiB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE,gBAAgB,EAAE;AAC7J,2BAA2B,eAAe,EAAE,iBAAiB,EAAE,qBAAqB,EAAE,8BAA8B,EAAE,gBAAgB,EAAE","sourcesContent":[".notification-blob { text-align: left; padding: 24px 16px 16px 16px; background: #F2FEFF; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }\n.notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }\n.notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }\n.notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }\n.notification-blob .notification-blob-header .title { font-size: 14px; line-height: 18px; margin-left: 10px; font-family: 'Source Sans Pro'; font-weight: 700; text-transform: uppercase; }\n.notification-blob .notification-blob-header .hour { font-size: 12px; line-height: 12px; font-family: 'Source Sans Pro'; font-weight: 400; margin-left: 5px; }\n.notification-blob .text { font-size: 12px; line-height: 16px; letter-spacing: 0.4px; font-family: 'Source Sans Pro'; font-weight: 400; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
