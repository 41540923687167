// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addToHomeScreenBanner {
  position: fixed;
  bottom: 0px;
  background-color: #0097A7;
  color: #fff;
  border: none;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  z-index: 11;
}
.addToHomeScreenBanner .main-banner-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.addToHomeScreenBanner .main-banner-container .banner-content {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 16px;
}

.addToHomeScreenBanner .main-banner-container .tips-content{
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 100%;
    background: #000;
}

.addToHomeScreenBanner .tips-content .close-button{
    position: absolute;
    display: flex;
    right: 10px;
}

.addToHomeScreenBanner .main-banner-container .showTips{
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    color:#0097A7;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
}

.cancel-button{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}
.sub-title{
    font-family: Lora;
    font-size: 17px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/common/AddToHomeScreenBanner/AddToHomeScreenBanner.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;EACX,WAAW;AACb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".addToHomeScreenBanner {\n  position: fixed;\n  bottom: 0px;\n  background-color: #0097A7;\n  color: #fff;\n  border: none;\n  padding: 15px;\n  cursor: pointer;\n  width: 100%;\n  z-index: 11;\n}\n.addToHomeScreenBanner .main-banner-container{\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.addToHomeScreenBanner .main-banner-container .banner-content {\n    margin-right: 10px;\n    margin-bottom: 0;\n    font-size: 16px;\n}\n\n.addToHomeScreenBanner .main-banner-container .tips-content{\n    margin-top: 15px;\n    display: flex;\n    width: 100%;\n    height: 100%;\n    background: #000;\n}\n\n.addToHomeScreenBanner .tips-content .close-button{\n    position: absolute;\n    display: flex;\n    right: 10px;\n}\n\n.addToHomeScreenBanner .main-banner-container .showTips{\n    padding: 5px;\n    background-color: white;\n    border-radius: 10px;\n    color:#0097A7;\n    padding-left: 8px;\n    padding-right: 8px;\n    margin-right: 10px;\n}\n\n.cancel-button{\n    display: flex;\n    width: 100%;\n    flex-direction: row-reverse;\n    margin-bottom: 10px;\n}\n.sub-title{\n    font-family: Lora;\n    font-size: 17px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
