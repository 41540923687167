// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    text-align: center;
}

.content .circle {
    margin: 0 auto 16px auto;
    width: 112px;
    height: 112px;
    border-radius: 250px;
}

.content .circle img {
    border-radius: 50%;
    width: 112px;
    height: 112px;
    object-fit: cover;
}

.content .content-title {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    overflow: hidden;
    line-height: unset;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
}

.content .title>a {
    color: #000000;
}

.content .all-stars {
    margin-bottom: 5px;
}

.content .all-stars .result {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/WithoutLogin/CommonThirdParty/components/FeaturedUser/FeaturedUser.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB;0BACsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".content {\n    text-align: center;\n}\n\n.content .circle {\n    margin: 0 auto 16px auto;\n    width: 112px;\n    height: 112px;\n    border-radius: 250px;\n}\n\n.content .circle img {\n    border-radius: 50%;\n    width: 112px;\n    height: 112px;\n    object-fit: cover;\n}\n\n.content .content-title {\n    margin-bottom: 12px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 24px;\n    overflow: hidden;\n    line-height: unset;\n    /* text-overflow: ellipsis;\n    white-space: nowrap; */\n}\n\n.content .title>a {\n    color: #000000;\n}\n\n.content .all-stars {\n    margin-bottom: 5px;\n}\n\n.content .all-stars .result {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 18px;\n    color: #000000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
