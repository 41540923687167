// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-virtual-assistant .back-btn{
  background: #00BCD4;
  color: #fff;
  margin-top: 2px;
}

.profile-virtual-assistant .back-btn:hover{
  background: #00BCD4;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Seller/SellerProfile/components/VirtualAssistant/VirtualAssistant.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".profile-virtual-assistant .back-btn{\n  background: #00BCD4;\n  color: #fff;\n  margin-top: 2px;\n}\n\n.profile-virtual-assistant .back-btn:hover{\n  background: #00BCD4;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
