// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-std-button {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Seller/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".seller-std-button {\n  background-color: #00bcd4;\n  height: 48px;\n  font-family: Source Sans Pro;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 18px;\n  text-align: left;\n  color: white;\n  padding: 15px 32px 15px 32px;\n  border: none;\n  outline: 0;\n  border-radius: 4px;\n  cursor: pointer;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
