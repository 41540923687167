// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lawyer-invitations { background: #F8F8F8; display: grid; grid-template-columns: repeat(1 1fr); row-gap: 24px; }

/* Dialog */
.dialog-question { text-align: center; }
.dialog-question-content { margin: 64px 117px; }
.dialog-question-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-question-content .content img { margin-bottom: 24px; }
.dialog-question-content .content .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.dialog-question-content .content .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; }
.dialog-question-content .content .actions { margin-top: 24px; display: flex; justify-content: center; column-gap: 24px; }`, "",{"version":3,"sources":["webpack://./src/pages/Lawyer/Dashboard/Invitations/Invitations.css"],"names":[],"mappings":"AAAA,sBAAsB,mBAAmB,EAAE,aAAa,EAAE,oCAAoC,EAAE,aAAa,EAAE;;AAE/G,WAAW;AACX,mBAAmB,kBAAkB,EAAE;AACvC,2BAA2B,kBAAkB,EAAE;AAC/C,uCAAuC,eAAe,EAAE,YAAY,EAAE,kBAAkB,EAAE,MAAM,EAAE,QAAQ,EAAE,gBAAgB,EAAE,kBAAkB,EAAE;AAClJ,wCAAwC,mBAAmB,EAAE;AAC7D,2CAA2C,kBAAkB,EAAE,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,EAAE;AACtH,0CAA0C,4BAA4B,EAAE,kBAAkB,EAAE,mBAAmB,EAAE,eAAe,EAAE,iBAAiB,EAAE,kBAAkB,EAAE;AACzK,6CAA6C,gBAAgB,EAAE,aAAa,EAAE,uBAAuB,EAAE,gBAAgB,EAAE","sourcesContent":[".lawyer-invitations { background: #F8F8F8; display: grid; grid-template-columns: repeat(1 1fr); row-gap: 24px; }\n\n/* Dialog */\n.dialog-question { text-align: center; }\n.dialog-question-content { margin: 64px 117px; }\n.dialog-question-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }\n.dialog-question-content .content img { margin-bottom: 24px; }\n.dialog-question-content .content .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }\n.dialog-question-content .content .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; }\n.dialog-question-content .content .actions { margin-top: 24px; display: flex; justify-content: center; column-gap: 24px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
